// OpenType Specification
// https://docs.microsoft.com/en-us/typography/opentype/spec/os2#usweightclass

export enum EFontWeight {
    ExtraLight = 200,
    Light = 300,
    Normal = 400,
    Medium = 500,
    SemiBold = 600,
    Bold = 700
}

export enum EFonts {
    Hatton = 'Hatton, serif',
    Montserrat = 'Montserrat, sans-serif'
}

export enum EColors {
    NaturalBlack = '#000',
    White = '#FFF',
    Gold = '#A78E52',
    GoldenSand = '#B9A55C',
    SpringWood = '#F0EEE4',
    Cameo = '#DBBEA8',
    GoldenLight = '#EAE2D7',
    LightOatmeal = '#FAF6ED',
    SilverChalice = '#a9a9a9',
    Alabaster = '#fbfbfb',
    SilverChalicer = '#aaaaaa',
    DoveGrey = '#707070',
    WildSand = '#f6f6f6',
    SwissCoffee = '#d2cdcd',
    //
    OuronyxLogo = '#ECE2D6',
    DisabledTile = '#D9D4D0',
    DarkOatmeal = '#E4DBCF',
    LightPanel = '#F4EEE7',
    DarkRed = '#8B0000',
    //
    ArchetypeRed = '#b80f0f',
    ArchetypeGreen = '#24897a',
    ArchetypeBlue = '#28247f',
    ArchetypeYellow = '#ffca00'
}

export enum EBreakpoints {
    ExtraSmall = 320,
    Small = 768,
    Medium = 1024,
    Large = 1280,
    ExtraLarge = 1920
}

export enum ETimes {
    MenuAnimationTime = 900
}

export enum ESizes {
    NavbarHeight = 65,
    GutterXS = 28,
    GutterS = 35,
    GutterM = 75
}

export enum EClinikoEndpoint {
    Patients = 'patients/{patientId}'
}

export enum EApiEndpoint {
    AppointmentBookingRequest = 'appointment_booking_request/',
    AppointmentBookingRequestAppointmentTime = 'appointment_booking_request/appointment_time/clinic/{clinicId}/appointment_type/{appointmentTypeId}',
    AppointmentNoShowCharge = 'appointment_no_show/charge',
    VirtualAppointment = 'cliniko/virtual_appointment/',
    ListClinic = 'cliniko/clinic/',
    AppointmentType = 'cliniko/appointment_type/{clinicId}',
    AppointmentTimesByClinic = 'cliniko/appointment_time/clinic/{clinicId}/appointment_type/{appointmentTypeId}/{date}',
    AppointmentTimesByPractitioner = 'cliniko/appointment_time/clinic/{clinicId}/practitioner/{practitionerId}/appointment_type/{appointmentTypeId}/{date}',
    AppointmentValidate = 'cliniko/appointment_time/validate/clinic/{clinicId}/appointment_type/{appointmentTypeId}/{startsAt}',
    ClinicById = 'cliniko/clinic/{clinicId}',
    DoctorsByClinic = 'cliniko/clinic/{clinicId}/practitioners',
    PractitionerByAppointmentAndTime = 'cliniko/clinic/{clinicId}/practitioners/appointment_type/{appointmentTypeId}/startsAt/{startsAt}',
    ClinicsByDoctor = 'cliniko/practitioners/{id}/clinics',
    ClinicRestrictedDates = 'cliniko/restricted_dates/{clinicId}',
    ClinicDashboardAppointments = 'clinic_dashboard/{clinicId}/appointments',
    User = 'user',
    GetBillable = 'checkout/billable/{clinicId}/{appointmentTypeId}',
    PostCart = 'checkout/cart',
    CheckoutMethods = 'checkout/methods',
    CheckoutPaymentsDetails = 'checkout/payments/details',
    CheckoutCartAddress = 'checkout/cart/address',
    CheckoutPayment = 'checkout/payment',
    CheckoutPlaceOrder = 'checkout/placeOrder',
    Doctors = 'cliniko/practitioners',
    UserLogin = 'login',
    StaffLogin = 'staff_login',
    UserLoginCheck = 'login_check',
    UserLoginCode = 'login_passcode',
    UserPasswordResetRequest = 'user/password_reset/request',
    UserPasswordReset = 'user/password_reset/reset',
    UserPassword = 'user/password',
    TwoFactorAuthCheck = '2fa_check',
    Patient = 'patient',
    PatientProfile = 'patient_profile/{patientId}',
    PatientAppointments = 'patient/appointments',
    PatientPractitioner = 'patient/practitioner',
    PatientClinic = 'patient/clinic',
    PatientTreatmentplan = 'patient/treatmentplan',
    PatientPersonalityArchetype = 'patient/psych_attr/personality_archetype',
    PatientImageUrl = 'patient/photo',
    PractitionerFirstConsultation = 'practitioner/appointment/first',
    PractitionerAppointment = 'practitioner/appointment',
    PractitionerPsychologicalAttributes = 'practitioner/psych_attr_survey_response/{patientId}',
    PractitionerRevenue = 'practitioner/revenue/{startDate}/{endDate}',
    PractitionerEarnings = 'practitioner/earnings/{startDate}/{endDate}',
    PractitionerInvoices = 'practitioner/invoices/{startDate}/{endDate}',
    PatientAttachments = 'patient/attachments',
    PatientAttachmentsFile = 'patient/attachments/{clinicId}/{downloadToken}',
    PractitionerFeedback = 'practitioner/feedback',
    PractitionerInstance = 'practitioner/instance',
    PatientAppointmentsCancel = 'patient/{clinicId}/appointments/{id}/cancel',
    PatientAppointmentsById = 'patient/{clinicId}/appointments/{id}',
    PsychologicalSurvey = 'psych_attr_survey/',
    PsychologicalArchetype = 'psych_attr_archetype/{respondentId}',
    PsychologicalSurveyById = 'psych_attr_survey/{surveyId}',
    PsychologicalSurveyCheckRespondent = 'psych_attr_survey/check_respondent/{surveyId}',
    ReceptionPatient = 'reception/{clinicId}/patient',
    ReceptionRefreshments = 'reception/patient/{patientId}/refreshments',
    ReceptionMakeup = 'reception/patient/{patientId}/makeup',
    ReceptionReferral = 'reception/patient/{clinicId}/{patientId}/referral_source',
    ReceptionAppointmentAttendance = 'reception/{clinicId}/appointment/{appointmentId}/attendance',
    InvoicePatient = 'invoice/patient/{clinicId}/{patientId}/{invoiceId}',
    TreatmentPlanGoals = 'treatmentplan/goals',
    TreatmentPlanPlan = 'treatmentplan/plan/{patientId}',
    TreatmentPlanPlanTransfer = 'treatmentplan/plan/{patientId}/transfer',
    TreatmentPlanPlanDelete = 'treatmentplan/plan/{patientId}/{planId}',
    TreatmentPlanPlanArchive = 'treatmentplan/plan/{patientId}/{planId}/archive',
    BaristaGetRefreshments = 'barista/refreshments/{clinicId}',
    BaristaAcknowledgeRefreshments = 'barista/refreshments/{id}',
    TreatmentConsent = 'treatment_consent/{patientId}',
    ShareConsent = 'data_sharing/consent',
    ShareConsentGet = 'data_sharing/consent/{patientId}',
    TreatmentZone = 'treatment/zone/',
    TreatmentGoal = 'treatment/goal/',
    TreatmentNote = 'treatment/note',
    TreatmentNotePatch = 'treatment/note/{noteId}',
    TreatmentNoteFinalise = 'treatment/note/{noteId}/finalise',
    TreatmentNoteBotoxVolume = 'treatment/note/botox/volume/{clinicId}/{barcode}',
    TreatmentAppointmentSummary = 'treatment/appointment_summary/{clinicId}/{appointmentId}/',
    TreatmentNoteOutcome = 'treatment_note/outcome',
    TreatmentObservationsPatch = 'treatment/observation/{id}',
    TreatmentObservations = 'treatment/observation/',
    TreatmentObservationsGet = 'treatment/observation/{clinicId}/{appointmentId}',
    TreatmentAesthetician = 'facial_aesthetician/treatment/note',
    TreatmentAestheticianGet = 'facial_aesthetician/treatment/note/{patientId}/{appointmentId}',
    DearProducts = 'dear/product/{clinicId}',
    DearProduct = 'dear/product/{clinicId}/{barcode}',
    DearReasons = 'dear/damaged_reason',
    DearProductDamaged = 'dear/damaged',
    DearProductDamagedByPractitioner = 'dear/damaged/{practitionerId}',
    StripeUserIntent = 'stripe/user/intent',
    StripeUserMethods = 'stripe/user/methods',
    StripeUserMethod = 'stripe/user/methods/{id}',
    GeoipCountry = 'geoip/country',
    ClinicConfig = 'config/{path}/{clinicId}',
    Config = 'config/',
    NewsletterSubscribe = 'newsletter/subscribe/{email}',
    FormsContactUs = 'forms/contact_us/',
    FormsPractitionerFeedback = 'forms/practitioner_feedback/'
}

export enum ERoutes {
    Home = '/',
    AdvisoryPanel = '/advisory-panel/',
    CallBack = '/call-back/',
    Virtual = '/virtual/',
    Conversation = '/conversation/',
    Journey = '/journey/',
    Contact = '/contact/',
    OurStory = '/our-story/',
    Doctors = '/doctors/',
    Experience = '/experience/',
    Services = '/services/',
    Appointment = '/appointment/',
    PanelHome = '/panel/',
    PanelStaffLogin = '/panel/staff/login',
    PanelStaffLanding = '/panel/staff/landing',
    PanelCustomerLogin = '/panel/customer/login',
    PanelStaffPin = '/panel/staff/pin',
    PanelStaffDashboard = '/panel/staff/dashboard',
    PanelStaffDashboardPatient = '/panel/staff/dashboard/patient',
    PanelStaffDashboardPatientById = '/panel/staff/dashboard/patient/{patientId}',
    PanelStaffDashboardPatientSearch = '/panel/staff/dashboard/patient-search',
    PanelStaffDashboardNoShow = '/panel/staff/dashboard/no-show',
    PanelStaffDashboardPsychologicalAttributes = '/panel/staff/dashboard/psychological-attributes/{patientId}',
    PanelStaffDashboardRefreshments = '/panel/staff/dashboard/refreshments',
    PanelStaffDashboardMessages = '/panel/staff/dashboard/messages',
    PanelStaffDashboardFeedback = '/panel/staff/dashboard/feedback',
    PanelStaffDashboardCreateEditTreatmentPlan = '/panel/staff/dashboard/create-edit-treatment-plan',
    PanelStaffDashboardViewTreatmentPlans = '/panel/staff/dashboard/treatment-plans/{patientId}',
    PanelStaffDashboardShareConsent = '/panel/staff/dashboard/share-consent/{patientId}',
    PanelStaffDashboardViewTreatmentObservations = '/panel/staff/dashboard/treatment-observations/{patientId}',
    PanelStaffDashboardViewAestheticianTreatment = '/panel/staff/dashboard/treatment-aesthetician/{patientId}',
    PanelStaffDashboardTreatmentNotes = '/panel/staff/dashboard/treatment-notes/',
    PanelBaristaRefreshmentsList = '/panel/staff/dashboard/barista-refreshments-list',
    PanelStaffDashboardDamagedProducts = '/panel/staff/dashboard/damaged-products',
    PanelCustomerDashboard = '/panel/customer/dashboard',
    PanelCustomerDashboardDetails = '/panel/customer/dashboard/details/{patientId}',
    PanelCustomerDashboardSavedPaymentDetails = '/panel/customer/dashboard/saved-payment-details',
    PanelCustomerDashboardCommunicationPrefs = '/panel/customer/dashboard/communication-preferences',
    PanelCustomerDashboardMyJourney = '/panel/customer/dashboard/my-journey',
    PanelCustomerDashboardPastAppointments = '/panel/customer/dashboard/past-appointments',
    PanelCustomerDashboardUpcomingAppointments = '/panel/customer/dashboard/upcoming-appointments',
    PanelCustomerDashboardMyTreatmentPlan = '/panel/customer/dashboard/my-treatment-plan',
    PanelCustomerDashboardMyFiles = '/panel/customer/dashboard/my-files',
    PanelWelcomeInternal = '/panel/welcome-internal',
    ArchetypesSurvey = '/survey/',
    ArchetypeRed = '/red/',
    ArchetypeGreen = '/green/',
    ArchetypeYellow = '/yellow/',
    ArchetypeBlue = '/blue/',
    Book = '/book/'
}

export enum ERoles {
    Practitioner = 'ROLE_PRACTITIONER',
    Receptionist = 'ROLE_RECEPTIONIST',
    Barista = 'ROLE_BARISTA',
    Nurse = 'ROLE_NURSE',
    MakeupArtist = 'ROLE_MAKEUP_ARTIST',
    SkincareConsultant = 'ROLE_SKINCARE_CONSULTANT'
}

/**
 * Not an enum but in this file so it's
 * obvious to update if new ERoles are added
 */
export const AdminRoles = [
    ERoles.Practitioner,
    ERoles.Receptionist,
    ERoles.Nurse,
    ERoles.Barista,
    ERoles.MakeupArtist,
    ERoles.SkincareConsultant
];

export enum ETreatmentNoteStatus {
    Draft = 'draft',
    Final = 'final',
    Locked = 'locked'
}

export enum ERefreshmentStatus {
    Pending = 1,
    Completed = 0
}
