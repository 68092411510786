import * as Styled from './OxForm.styled';

import React, { useContext, useState } from 'react';

import { AlertContext } from 'src/context/AlertContext';

type TProps = {
    onFormSubmit: (data: FormData, e: SubmitEvent) => void;
};

export type TSubmitButtonProps = {
    loading: 1 | 0;
    disabled: boolean;
};

export type TFormChildCallbackProps = {
    submitButtonProps: TSubmitButtonProps;
};

export const OxForm = React.forwardRef<HTMLFormElement, SCProps<'form', TProps>>(
    ({ children, onFormSubmit, ...props }: SCProps<'form', TProps>, ref): JSX.Element => {
        const { showAlert } = useContext(AlertContext);
        const [loading, setLoading] = useState<boolean>(false);
        const [disabled, setDisabled] = useState<boolean>(false);

        const submitButtonProps: TSubmitButtonProps = {
            loading: loading ? 1 : 0,
            disabled
        };

        const showLoading = (state: boolean) => {
            setDisabled(state);
            setLoading(state);
        };

        const handleFormSubmit = async (e: React.FormEvent<HTMLFormElement>): Promise<void> => {
            showLoading(true);
            e.preventDefault();
            const form = e.currentTarget;
            const formData = new FormData(form);
            const isValid = form.checkValidity();
            if (isValid) {
                try {
                    await onFormSubmit(formData, e);
                } catch (err) {
                    console.error(err);
                    showAlert(err.error);
                } finally {
                    showLoading(false);
                }
            }
        };

        return (
            <Styled.Container {...props} ref={ref} onSubmit={handleFormSubmit}>
                {typeof children === 'function' ? children({ submitButtonProps }) : children}
            </Styled.Container>
        );
    }
);
