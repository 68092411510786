import axios, { AxiosRequestConfig } from 'axios';

import { API_ENDPOINT, CLINIKO_SITE_URL_DUBAI, WS_ENDPOINT } from 'src/config/constants';
import { CLINIKO_SITE_URL_LONDON } from 'src/config/constants';
import { clearAllCaches, getFromCache, saveToCache } from 'src/services/api/api.cache';
import isClinicIdDubai from 'src/services/clinic/isClinicIdDubai';

export const getUrl = (endpoint: string): string => {
    if (endpoint.includes('http')) return endpoint;
    return API_ENDPOINT + endpoint;
};

export const getWSUrl = (endpoint?: string): string => {
    endpoint = endpoint || '';
    if (endpoint.startsWith('ws')) return endpoint;
    return WS_ENDPOINT + endpoint;
};

export const getClinikoLondonUrl = (endpoint: string): string => {
    if (endpoint.includes('http')) return endpoint;
    return CLINIKO_SITE_URL_LONDON + endpoint;
};

export const getClinikoDubaiUrl = (endpoint: string): string => {
    if (endpoint.includes('http')) return endpoint;
    return CLINIKO_SITE_URL_DUBAI + endpoint;
};

export const getClinikoUrlByClinic = ({
    endpoint,
    clinicId
}: {
    endpoint: string;
    clinicId: string | number;
}): string => {
    return isClinicIdDubai(clinicId) ? getClinikoDubaiUrl(endpoint) : getClinikoLondonUrl(endpoint);
};

type TRequestOptions = AxiosRequestConfig &
    Partial<{
        credentials: boolean;
        cacheable: boolean;
        alwaysRespond?: boolean;
    }>;

export const applyOptions = (options: TRequestOptions): Partial<TRequestOptions> => {
    const basicAuth = btoa(`staging:ouronyx`);
    const credentials = localStorage.getItem('ornx_user_token');
    const { headers, ...params } = options || {};
    const pureHeaders = { ...headers };
    if (options && options.credentials) {
        pureHeaders['X-AUTH-TOKEN'] = credentials;
        pureHeaders['Authorization'] = `Basic ${basicAuth}`;
    }
    return {
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            ...pureHeaders
        },
        ...params
    };
};

export async function apiGetCall<P>(
    endpoint: string,
    options?: TRequestOptions
): Promise<P | null> {
    if (options?.cacheable && (await getFromCache(endpoint, !!options?.credentials))) {
        return await getFromCache<P>(endpoint, !!options?.credentials);
    }

    try {
        const response = await axios.get(getUrl(endpoint), applyOptions(options || {}));

        options?.cacheable && saveToCache(endpoint, response.data, options?.credentials);

        if (response.headers['ouronyx-clear-api-cache']) {
            clearAllCaches();
        }

        return response.data;
    } catch (err) {
        return err.response?.data || { error: err.message } || err || null;
    }
}

export async function apiPostCall<P>(
    endpoint: string,
    body?: BodyInit,
    options?: TRequestOptions
): Promise<P | { success: boolean } | null> {
    try {
        const response = await axios.post(getUrl(endpoint), body, applyOptions(options || {}));
        if (!response.data && options?.alwaysRespond) {
            return {
                success: response.status >= 200 && response.status < 300
            };
        }

        return response.data;
    } catch (err) {
        return err.response.data || { error: err.message, code: err.code } || err || null;
    }
}

export async function apiPutCall<P>(
    endpoint: string,
    body: BodyInit,
    options?: TRequestOptions
): Promise<P | null> {
    try {
        const response = await axios.put(getUrl(endpoint), body, applyOptions(options || {}));
        return response.data;
    } catch (err) {
        return err.response.data || { error: err.message } || err || null;
    }
}

export async function apiPatchCall<P>(
    endpoint: string,
    body: BodyInit,
    options?: TRequestOptions
): Promise<P | null> {
    try {
        const response = await axios.patch(getUrl(endpoint), body, applyOptions(options || {}));
        return response.data;
    } catch (err) {
        return err.response.data || { error: err.message } || err || null;
    }
}

export async function apiDeleteCall<P>(
    endpoint: string,
    options?: TRequestOptions
): Promise<P | null> {
    try {
        const response = await axios.delete(getUrl(endpoint), applyOptions(options || {}));
        return response.data;
    } catch (err) {
        return err.response.data || { error: err.message } || err || null;
    }
}
