import { EFontWeight, EFonts } from 'src/config/enums';
import styled, { css } from 'styled-components';

import { OxIcon } from 'src/components/OxIcon';
import { OxLink } from 'src/components/OxLink';
import { createTextStyle } from 'src/utils/createTextStyle';
import { fluidSizing } from 'src/utils';
import { hoverUnderlineAnimation } from 'src/styles/animations';

export const Container = styled.div(
    ({ theme }) => css`
        display: flex;
        flex-direction: column;

        ${theme.breakpoints.up('s')} {
            ${(props) =>
                props.justSocial
                    ? ``
                    : `${fluidSizing([{ prop: 'margin-bottom', values: [15, 15, 15] }])}`};
        }

        & > a {
            line-height: 1;
            ${createTextStyle(EFonts.Montserrat, EFontWeight.Medium, [10, 10, 10], [18, 18, 18])}
            ${fluidSizing([{ prop: 'margin-bottom', values: [10, 10, 10] }])}
        }

        ul {
            li {
                :not(:last-child) {
                    ${fluidSizing([{ prop: 'margin-right', values: [10, 10, 10] }])}
                }
            }
        }
    `
);

export const List = styled.ul`
    display: flex;
    justify-content: flex-start;
    align-items: center;

    ${fluidSizing([{ prop: 'height', values: [30, 30, 30] }])}
`;

export const Tag = styled.span`
    ${createTextStyle(EFonts.Montserrat, EFontWeight.SemiBold, [12, 12, 12], [16, 16, 16])}
    ${fluidSizing([{ prop: 'margin-bottom', values: [10, 10, 10] }])}
`;

export const Title = styled.h4`
    ${createTextStyle(EFonts.Hatton, EFontWeight.Medium, [20, 20, 21], [28, 28, 24])}
    ${fluidSizing([{ prop: 'margin-bottom', values: [5, 5, 8] }])}
`;

export const Link = styled(OxLink)(
    ({ theme }) => css`
        width: fit-content;
        ${hoverUnderlineAnimation({ theme })};
    `
);

export const IconLink = styled(OxLink)(
    () => css`
        align-items: center;
        display: flex;
        justify-content: center;
    `
);

const smallHeight = [8, 10, 12, 14, 18];
const largeHeight = [18, 20, 22, 24, 28];

export const Icon = styled(OxIcon)<{ small?: boolean }>(
    ({ small }) => css`
        ${fluidSizing([{ prop: 'height', values: small ? smallHeight : largeHeight }])}
    `
);
