import * as Styled from './OxButton.styled';

import React, {
    ButtonHTMLAttributes,
    ReactNode,
    forwardRef,
    useContext,
    useMemo,
    ForwardRefExoticComponent
} from 'react';
import { debounce } from 'lodash';

import { OxIcon } from 'src/components/OxIcon';
import { ThemedInputContext } from 'src/context/ThemedInputContext';

type TProps = ButtonHTMLAttributes<HTMLButtonElement | HTMLAnchorElement> & {
    icon?: boolean;
    children: ReactNode;
    loading?: boolean;
    asAnchor?: boolean;
    debounceClick?: boolean;
    debounceClickDeps?: any[];
    debounceTimeout?: number;
    to?: string;
    forceExternal?: boolean;
};

export const OxButton: React.ForwardRefExoticComponent<TProps> = forwardRef(
    (
        {
            children,
            icon,
            loading,
            type,
            asAnchor,
            debounceClick,
            debounceClickDeps = [],
            debounceTimeout = 500,
            onClick,
            to,
            ...props
        }: SCProps<'button', TProps>,
        ref
    ): JSX.Element => {
        const context = useContext(ThemedInputContext);
        const Component = asAnchor ? Styled.Link : Styled.Button;
        const childNodes = [children];
        icon && !loading && childNodes.push(<OxIcon key="icon" name="arrow-1" title={'Submit'} />);
        !!loading && childNodes.push(<Styled.Spinner key="spinner" $contextTheme={context} />);

        const debouncedClickHandler = useMemo(
            () => debounceClick && onClick && debounce(onClick, debounceTimeout),
            [...debounceClickDeps, debounceTimeout]
        );

        return React.createElement(
            Component,
            {
                ...props,
                ref,
                type: type || 'button',
                $contextTheme: context,
                onClick: debounceClick ? debouncedClickHandler : onClick,
                to,
                icon
            },
            childNodes
        );
    }
);
