import styled, { css } from 'styled-components';
import { createTextStyle } from 'src/utils/createTextStyle';
import { EFonts, EFontWeight } from 'src/config/enums';
import { fluidSizing } from 'src/utils';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    ${fluidSizing([{ prop: 'margin-bottom', values: [20, 20, 20] }])}
`;

export const Title = styled.div(
    ({ theme }) => css`
        ${createTextStyle(EFonts.Montserrat, EFontWeight.Bold, [11, 11, 11], [14, 14, 14])}
        color: ${theme.colors.basic.white};
    `
);

export const Description = styled.div(
    ({ theme }) => css`
        ${createTextStyle(EFonts.Montserrat, EFontWeight.Light, [11, 11, 11], [14, 14, 14])}
        color: ${theme.colors.basic.white};
        text-align: justify;
    `
);
