import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    border: none;
    outline: none;
    list-style: none;
    box-sizing: border-box;
    border-collapse: inherit;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    -webkit-tap-highlight-color: transparent;
    font-kerning: none;
    text-rendering: optimizeSpeed;
  }

  a {
    color: inherit;
    text-decoration: none;
    display: block;
    cursor: pointer;
  }

  button {
    outline: 0;
    cursor: pointer;
    background: transparent;
    color: inherit;
  }

  img {
    max-width: 100%;
  }

  ol, ul {
    list-style: none;
  }

  input {
    color: inherit;
    font-family: inherit;
    font-weight: inherit;
    ::placeholder {
      font-family: inherit;
      color: inherit;
      font-weight: inherit;
    }
  }

  .ReactModal__Body--open {
    overflow-y: hidden;
  }

`;
