interface LooseObject {
    [key: string]: any;
}

declare global {
    interface Window {
        dataLayer: LooseObject[];
    }
}

interface FireEventParams {
    event: string;
    props?: LooseObject;
}

export const fireEvent = ({ event, props }: FireEventParams) => {
    window.dataLayer = window.dataLayer || [];

    window.dataLayer.push({
        event,
        ...props
    });
};
