import * as Styled from './OxAlert.styled';

import { EInputTheme, OxThemedInput } from 'src/components/OxThemedInput';
import React, { createRef, useContext, useEffect } from 'react';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

import { EAlertVariant, AlertContext } from 'src/context/AlertContext';
import { OxButton } from 'src/components/OxButton';

export const OxAlert = (): JSX.Element => {
    const alertContext = useContext(AlertContext);
    const modalRef = createRef();

    useEffect(() => {
        if (alertContext.alert !== null) {
            disableBodyScroll(modalRef.current);
        } else {
            enableBodyScroll(modalRef.current);
        }
    }, [alertContext.alert]);

    return (
        <Styled.Modal show={alertContext.alert !== null} id="alertModal">
            <Styled.ModalOverlay ref={modalRef}>
                <Styled.Content>
                    {alertContext.alert?.header && (
                        <Styled.Header>{alertContext.alert.header}</Styled.Header>
                    )}
                    {alertContext.alert?.title && (
                        <Styled.Title>{alertContext.alert.title}</Styled.Title>
                    )}
                    {alertContext.alert?.message && (
                        <>
                            {Array.isArray(alertContext.alert?.message) ? (
                                (alertContext.alert?.message as []).map((message, index) => (
                                    <Styled.Message key={index}>{message}</Styled.Message>
                                ))
                            ) : (
                                <Styled.Message>{alertContext.alert.message}</Styled.Message>
                            )}
                        </>
                    )}
                    {alertContext.alert?.type === EAlertVariant.Confirm && (
                        <Styled.ConfirmButtonsContainer>
                            <OxThemedInput theme={EInputTheme.GoldAlternative}>
                                {!alertContext.alert?.onlyConfirm && (
                                    <OxButton
                                        icon
                                        onClick={(): void => {
                                            alertContext.alert?.onCancel &&
                                                alertContext.alert?.onCancel();
                                            alertContext.hideAlert();
                                        }}
                                    >
                                        {alertContext.alert?.noCancel ? 'NO' : 'CANCEL'}
                                    </OxButton>
                                )}
                            </OxThemedInput>
                            <OxThemedInput theme={EInputTheme.BackgroundGoldAlternative}>
                                <OxButton
                                    icon
                                    id={alertContext.alert.buttonId}
                                    onClick={(): void => {
                                        alertContext.alert?.onConfirm &&
                                            alertContext.alert?.onConfirm();
                                        alertContext.alert?.hideAfterConfirm &&
                                            alertContext.hideAlert();
                                    }}
                                >
                                    {alertContext.alert?.confirmCTA ??
                                        (alertContext.alert?.noCancel ? 'YES' : 'OK')}
                                </OxButton>
                            </OxThemedInput>
                        </Styled.ConfirmButtonsContainer>
                    )}
                    {(alertContext.alert?.type === EAlertVariant.Error ||
                        alertContext.alert?.type === EAlertVariant.Success) && (
                        <OxThemedInput theme={EInputTheme.BackgroundGoldAlternative}>
                            <OxButton
                                icon
                                id={alertContext.alert.buttonId}
                                onClick={(): void => alertContext.hideAlert()}
                            >
                                OK
                            </OxButton>
                        </OxThemedInput>
                    )}
                </Styled.Content>
            </Styled.ModalOverlay>
        </Styled.Modal>
    );
};
