import React, { ReactNode } from 'react';
import { TThemedInputContext, ThemedInputContext } from 'src/context/ThemedInputContext';

import { transparentize } from 'polished';
import { useTheme } from 'styled-components';

export enum EInputTheme {
    Gold = 'GOLD',
    White = 'WHITE',
    Grey = 'GREY',
    WhiteTransparent = 'WHITE_TRANSPARENT',
    WhiteTransparentAlternative = 'WHITE_TRANSPARENT_ALTERNAITVE',
    GoldTransparent = 'GOLD_TRANSPARENT',
    GoldTransparentAlternative = 'GOLD_TRANSPARENT_ALTERNATIVE',
    GoldTransparentAlternative2 = 'GOLD_TRANSPARENT_ALTERNATIVE_2',
    GoldTransparentAlternative3 = 'GOLD_TRANSPARENT_ALTERNATIVE_3',
    GoldTransparentAlternative4 = 'GOLD_TRANSPARENT_ALTERNATIVE_4',
    GoldLight = 'GOLD_LIGHT',
    GoldAlternative = 'GOLD_ALTERNATIVE',
    BackgroundGold = 'BACKGROUND_GOLD',
    BackgroundGoldAlternative = 'BACKGROUND_GOLD_ALTERNATIVE',
    BackgroundWhite = 'BACKGROUND_WHITE',
    BackgroundWhiteAlternative = 'BACKGROUND_WHITE_ALTERNATIVE'
}

type TProps = {
    theme: EInputTheme;
    children: ReactNode;
};

export const OxThemedInput = (props: TProps): JSX.Element => {
    const theme = useTheme();
    const inputTheme: TThemedInputContext = {
        borderColor: '',
        color: '',
        backgroundColor: '',
        hoverBorderColor: '',
        hoverColor: '',
        hoverBackgroundColor: '',
        spinnerColor: ''
    };

    switch (props.theme) {
        case EInputTheme.White:
        case EInputTheme.WhiteTransparent:
            inputTheme.borderColor = theme.colors.basic.white;
            inputTheme.color = theme.colors.basic.white;
            inputTheme.backgroundColor = transparentize(0.8, theme.colors.basic.white);
            inputTheme.hoverBorderColor = theme.colors.basic.white;
            inputTheme.hoverColor = theme.colors.secondary.variant;
            inputTheme.hoverBackgroundColor = theme.colors.basic.white;
            inputTheme.spinnerColor = theme.colors.secondary.variant;
            break;
        case EInputTheme.WhiteTransparentAlternative:
            inputTheme.borderColor = theme.colors.basic.white;
            inputTheme.color = theme.colors.basic.white;
            inputTheme.backgroundColor = 'transparent';
            inputTheme.hoverBorderColor = theme.colors.basic.white;
            inputTheme.hoverColor = theme.colors.secondary.variant;
            inputTheme.hoverBackgroundColor = theme.colors.basic.white;
            inputTheme.spinnerColor = theme.colors.secondary.variant;
            break;
        case EInputTheme.Grey:
            inputTheme.borderColor = theme.colors.secondary.variant;
            inputTheme.color = theme.colors.secondary.variant;
            inputTheme.backgroundColor = theme.colors.basic.darkOatmeal;
            inputTheme.hoverBorderColor = theme.colors.secondary.variant;
            inputTheme.hoverColor = theme.colors.secondary.variant;
            inputTheme.hoverBackgroundColor = theme.colors.basic.darkOatmeal;
            inputTheme.spinnerColor = theme.colors.secondary.variant;
            break;
        case EInputTheme.Gold:
        case EInputTheme.GoldTransparent:
            inputTheme.borderColor = theme.colors.primary.main;
            inputTheme.color = theme.colors.primary.main;
            inputTheme.backgroundColor = transparentize(0.75, theme.colors.basic.white);
            inputTheme.hoverBorderColor = theme.colors.primary.main;
            inputTheme.hoverColor = theme.colors.basic.white;
            inputTheme.hoverBackgroundColor = theme.colors.primary.main;
            inputTheme.spinnerColor = theme.colors.primary.main;
            break;
        case EInputTheme.GoldTransparentAlternative:
            inputTheme.borderColor = theme.colors.primary.main;
            inputTheme.color = theme.colors.primary.main;
            inputTheme.backgroundColor = transparentize(0.65, theme.colors.basic.white);
            inputTheme.hoverBorderColor = theme.colors.primary.main;
            inputTheme.hoverColor = theme.colors.basic.white;
            inputTheme.hoverBackgroundColor = theme.colors.primary.main;
            inputTheme.spinnerColor = theme.colors.primary.main;
            break;
        case EInputTheme.GoldTransparentAlternative2:
            inputTheme.borderColor = theme.colors.basic.ouronyxLogo;
            inputTheme.color = theme.colors.primary.main;
            inputTheme.backgroundColor = 'transparent';
            inputTheme.hoverBorderColor = theme.colors.primary.main;
            inputTheme.hoverColor = theme.colors.basic.white;
            inputTheme.hoverBackgroundColor = theme.colors.primary.main;
            inputTheme.spinnerColor = theme.colors.primary.main;
            break;
        case EInputTheme.GoldTransparentAlternative3:
            inputTheme.borderColor = 'transparent';
            inputTheme.color = theme.colors.basic.black;
            inputTheme.backgroundColor = 'transparent';
            inputTheme.hoverBorderColor = 'transparent';
            inputTheme.hoverColor = theme.colors.basic.white;
            inputTheme.hoverBackgroundColor = theme.colors.primary.main;
            inputTheme.spinnerColor = theme.colors.primary.main;
            break;
        case EInputTheme.GoldTransparentAlternative4:
            inputTheme.borderColor = theme.colors.basic.white;
            inputTheme.color = theme.colors.primary.main;
            inputTheme.backgroundColor = transparentize(0.45, theme.colors.basic.white);
            inputTheme.hoverBorderColor = theme.colors.primary.main;
            inputTheme.hoverColor = theme.colors.basic.white;
            inputTheme.hoverBackgroundColor = theme.colors.primary.main;
            inputTheme.spinnerColor = theme.colors.primary.main;
            break;
        case EInputTheme.GoldAlternative:
            inputTheme.borderColor = theme.colors.primary.main;
            inputTheme.color = theme.colors.primary.main;
            inputTheme.backgroundColor = transparentize(0.75, theme.colors.basic.white);
            inputTheme.hoverBorderColor = theme.colors.primary.main;
            inputTheme.hoverColor = theme.colors.primary.main;
            inputTheme.hoverBackgroundColor = theme.colors.basic.white;
            inputTheme.spinnerColor = theme.colors.primary.main;
            break;
        case EInputTheme.GoldLight:
            inputTheme.backgroundColor = theme.colors.basic.goldenLight;
            inputTheme.borderColor = theme.colors.basic.goldenLight;
            inputTheme.color = theme.colors.primary.main;
            inputTheme.hoverBorderColor = theme.colors.primary.main;
            inputTheme.hoverColor = theme.colors.basic.white;
            inputTheme.hoverBackgroundColor = theme.colors.primary.main;
            inputTheme.spinnerColor = theme.colors.primary.main;
            break;
        case EInputTheme.BackgroundGold:
            inputTheme.backgroundColor = theme.colors.primary.main;
            inputTheme.borderColor = theme.colors.primary.main;
            inputTheme.color = theme.colors.basic.white;
            inputTheme.spinnerColor = theme.colors.basic.white;
            break;
        case EInputTheme.BackgroundGoldAlternative:
            inputTheme.backgroundColor = theme.colors.primary.main;
            inputTheme.borderColor = theme.colors.primary.main;
            inputTheme.color = theme.colors.basic.white;
            inputTheme.hoverBorderColor = theme.colors.primary.main;
            inputTheme.hoverColor = theme.colors.primary.main;
            inputTheme.hoverBackgroundColor = transparentize(0.75, theme.colors.basic.white);
            inputTheme.spinnerColor = theme.colors.basic.white;
            break;
        case EInputTheme.BackgroundWhite:
            inputTheme.backgroundColor = theme.colors.basic.white;
            inputTheme.borderColor = theme.colors.primary.main;
            inputTheme.color = theme.colors.primary.main;
            inputTheme.spinnerColor = theme.colors.primary.main;
            break;
        case EInputTheme.BackgroundWhiteAlternative:
            inputTheme.backgroundColor = theme.colors.basic.white;
            inputTheme.borderColor = theme.colors.basic.ouronyxLogo;
            inputTheme.color = theme.colors.basic.black;
            inputTheme.spinnerColor = theme.colors.primary.main;
            break;
    }

    return (
        <ThemedInputContext.Provider value={inputTheme}>
            {props.children}
        </ThemedInputContext.Provider>
    );
};
