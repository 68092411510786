import * as Styled from './OxImage.styled';

import { GatsbyImage as Img, getImage, IGatsbyImageData } from 'gatsby-plugin-image';
import { convertToBgImage } from 'gbimage-bridge';
import React, { useCallback, useMemo } from 'react';
import { OxImagePreload } from 'src/components/OxImage/components/OxImagePreload';

export type TFluidImages = TImage | TImage[];

export type TOxImageProps = {
    asBackground?: boolean;
    image?: string | IGatsbyImageData;
    fluidImages?: TFluidImages;
    objectFitContain?: boolean;
    objectPosition?: string;
    children?: React.ReactNode;
    eagerLoad?: boolean;
};

export type TImage = { media?: string } & IGatsbyImageData;

export const OxImage = ({
    asBackground,
    image,
    fluidImages = [],
    objectFitContain,
    objectPosition,
    alt = '',
    children,
    eagerLoad,
    ...props
}: SCProps<'div' | 'img', TOxImageProps>): JSX.Element => {
    const loading = eagerLoad ? 'eager' : 'lazy';

    const sharedProps = {
        fetchpriority: eagerLoad ? 'high' : '',
        loading: loading,
        alt,
        imgStyle: {
            ...(objectFitContain ? { objectFit: `contain` } : {}),
            ...(objectPosition ? { objectPosition } : {})
        },
        ...props
    };

    if (fluidImages && typeof fluidImages === 'object' && !Array.isArray(fluidImages)) {
        fluidImages = [fluidImages];
    }

    // Filter out array entries that are falsy or only have media key
    fluidImages = fluidImages
        .filter(Boolean)
        .filter((image: TImage) => Object.keys(image).filter((key) => key !== 'media').length > 0);

    const getAndConvertImage = useCallback(
        (image: TImage, runConvert = true) => {
            let gotImage = getImage(image);

            if (asBackground && runConvert) gotImage = convertToBgImage(image);

            return gotImage;
        },
        [asBackground]
    );

    const ImageComponents = useMemo<JSX.Element[]>((): JSX.Element[] => {
        const Images = fluidImages.map((image: IGatsbyImageData, index: number) => {
            const artDirected = fluidImages.length > 1;
            if (asBackground) {
                return (
                    <Styled.BackgroundImage
                        key={`background-image-${index}`}
                        $artDirected={artDirected}
                        $media={image.media}
                        {...getAndConvertImage(image)}
                        {...sharedProps}
                    >
                        {children}
                    </Styled.BackgroundImage>
                );
            } else {
                return (
                    <Styled.Img
                        key={`gatsby-image-${index}`}
                        image={image}
                        $artDirected={artDirected}
                        $media={image.media}
                        {...sharedProps}
                    >
                        {children}
                    </Styled.Img>
                );
            }
        });

        if (Images.length === 0) {
            if (asBackground) {
                // Can't really lazyload a BG image without some JS so leaving as a placeholder
                Images.push(
                    <Styled.BgImage
                        key="background-image"
                        {...getAndConvertImage(image)}
                        {...sharedProps}
                    >
                        {children}
                    </Styled.BgImage>
                );
            } else if (typeof image === 'string') {
                Images.push(<img key="string-image" src={image} {...sharedProps} />);
            } else {
                Images.push(<Img key="gatsby-image" image={image} {...sharedProps} />);
            }
        }

        return Images;
    }, [getAndConvertImage, fluidImages, asBackground]);

    return (
        <>
            <OxImagePreload
                eagerLoad={eagerLoad}
                fluidImages={fluidImages}
                image={image}
                sizes={props.sizes}
            />
            {ImageComponents}
        </>
    );
};
