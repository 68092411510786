import {
    TAppointmentDate,
    TAppointmentType,
    TClinic,
    TError,
    TPractitioner,
    TRestrictedDates
} from './api.types';

import { EApiEndpoint, EClinikoEndpoint } from 'src/config/enums';
import { apiGetCall, getClinikoUrlByClinic } from './api.core';
import { applyValues } from 'src/utils/applyValues';
import { getParamString } from 'src/utils/getParamString';

export const clinikoGetAppointmentTypes = async (
    clinicId: string
): Promise<TAppointmentType[] | null> => {
    return await apiGetCall(applyValues(EApiEndpoint.AppointmentType, { clinicId }));
};

export const clinikoGetClinicList = async (): Promise<TClinic[] | null> => {
    return await apiGetCall(EApiEndpoint.ListClinic, {
        cacheable: true
    });
};

export const getAppointmentTimesByClinic = async (values: {
    clinicId: string | number;
    appointmentTypeId: string;
    date: string;
}): Promise<(TAppointmentDate[] & Partial<TError>) | null> => {
    return await apiGetCall(applyValues(EApiEndpoint.AppointmentTimesByClinic, values));
};

export const getAppointmentTimesByPractitioner = async (values: {
    clinicId: string | number;
    appointmentTypeId: string;
    practitionerId: string;
    date: string;
}): Promise<(TAppointmentDate[] & Partial<TError>) | null> => {
    return await apiGetCall(applyValues(EApiEndpoint.AppointmentTimesByPractitioner, values));
};

export const validateAppointment = async (
    values: {
        clinicId: number;
        appointmentTypeId: string;
        startsAt: string;
    },
    practitionerId?: string
): Promise<TAppointmentDate | null> => {
    return await apiGetCall(
        applyValues(EApiEndpoint.AppointmentValidate, values) +
            (practitionerId ? `?practitionerId=${practitionerId}` : '')
    );
};

export const clinikoGetDoctorsByClinic = async (values: {
    clinicId: string;
}): Promise<TPractitioner[] | null> => {
    return await apiGetCall(applyValues(EApiEndpoint.DoctorsByClinic, values));
};

export const clinikoGetPractitionerByAppointmentAndTime = async (
    values: {
        clinicId: number;
        appointmentTypeId: string;
        startsAt: string;
    },
    optionalValues?: { preferredPractitionerId?: string; archetype?: string }
): Promise<TPractitioner | null> => {
    return await apiGetCall(
        applyValues(EApiEndpoint.PractitionerByAppointmentAndTime, values) +
            getParamString(optionalValues)
    );
};

export const clinikoGetClinicsByDoctor = async (values: {
    id: string;
}): Promise<TClinic[] | null> => {
    return await apiGetCall(applyValues(EApiEndpoint.ClinicsByDoctor, values));
};

export const clinikoGetDoctors = async (): Promise<TPractitioner[] | null> => {
    return await apiGetCall(EApiEndpoint.Doctors);
};

export const getClinicById = async (values: { clinicId: string }): Promise<TClinic | null> => {
    return await apiGetCall(applyValues(EApiEndpoint.ClinicById, values));
};

export const clinikoRestrictedDates = async (values: {
    clinicId: number;
}): Promise<TRestrictedDates | null> => {
    return await apiGetCall(applyValues(EApiEndpoint.ClinicRestrictedDates, values));
};

export const clinikoPatientUrl = (values: {
    patientId: string | number;
    clinicId: number;
}): string => {
    return getClinikoUrlByClinic({
        endpoint: applyValues(EClinikoEndpoint.Patients, values),
        clinicId: values.clinicId
    });
};
