import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { createGlobalStyle } from 'styled-components';
import theme from 'src/config/theme';

export default createGlobalStyle`
  html {
    font-size: 62.5%;
    -webkit-text-size-adjust: none;
  }

  body {
    font-family: ${theme.fonts.main};
    font-weight: ${theme.fontWeights.medium};
    transition: opacity 0.3s ease-out;
  }

  /* Safari toolbar hack
  
  body {
    position: fixed;
    overflow: hidden;
  }

  #___gatsby {
    overflow:scroll;
    height: 100vh;
    width: 100vw;
  }

  */

  #gatsby-focus-wrapper {
    height: 100%;
  }

  .invisible-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .dragging-event {
    cursor: grab !important;
  }

`;
