import { EFontWeight, EFonts } from 'src/config/enums';
import styled, { css } from 'styled-components';

import { OxIcon } from '../OxIcon';
import { TThemedInputContext } from 'src/context/ThemedInputContext';
import { createTextStyle } from 'src/utils/createTextStyle';
import { fluidSizing } from 'src/utils';

export const Container = styled.div<{
    hidden?: boolean;
}>(
    ({ hidden }) => css`
        position: relative;
        ${hidden &&
        css`
            margin-bottom: 0;
        `}
    `
);

export const Input = styled.input<{
    customTheme: TThemedInputContext;
    invalid: boolean;
}>(
    ({ theme, customTheme, invalid }) => css`
        width: 100%;
        border-width: 1px;
        border-style: solid;
        border-color: ${customTheme.borderColor} !important;
        background-color: ${customTheme.backgroundColor};
        color: ${customTheme.color};
        transition: 200ms;
        -webkit-appearance: none;
        ${createTextStyle(EFonts.Montserrat, EFontWeight.Medium, [10, 10, 10], [18, 18, 18])}
        ${fluidSizing([
            { prop: 'height', values: [30, 30, 30] },
            { prop: 'border-radius', values: [15, 15, 15] },
            { prop: 'padding-left', values: [15, 15, 15] },
            { prop: 'padding-right', values: [15, 15, 15] }
        ])}
    &:focus {
            color: ${customTheme.hoverColor};
            border-color: ${customTheme.hoverBorderColor};
            background-color: ${customTheme.hoverBackgroundColor};
        }
        &:disabled {
            background-color: ${theme.colors.basic.goldenLight};
            -webkit-text-fill-color: ${customTheme.color};
            opacity: 0.7;
        }
        &:invalid {
            box-shadow: none;
        }
        ${invalid &&
        css`
            border-color: ${theme.colors.basic.darkRed} !important;
        `}

        & + svg {
            color: ${customTheme.hoverColor};
        }

        @media print {
            border: none;
            font-size: 1.6rem;
            height: 3rem;
            padding-left: 0;
            padding-right: 0;
        }
    `
);

export const Icon = styled(OxIcon)<{
    inputRole?: string;
    customTheme: TThemedInputContext;
}>(({ inputRole, customTheme }) => {
    const isDropdown = inputRole === 'dropdown';
    return css`
        position: absolute;
        right: 0;
        top: 0;
        color: ${customTheme.color};
        ${fluidSizing([
            { prop: 'height', values: [10, 10, 10] },
            { prop: 'top', values: [10, 10, 10] },
            { prop: 'right', values: [15, 15, 15] }
        ])}
        ${isDropdown &&
        css`
            transform: rotate(90deg);
        `}
    `;
});

export const Error = styled.div(
    ({ theme }) => css`
        position: absolute;
        width: 100%;
        text-align: center;
        top: 100%;
        color: ${theme.colors.basic.darkRed};
        ${fluidSizing([{ prop: 'line-height', values: [16, 16, 16] }])};
        ${createTextStyle(EFonts.Montserrat, EFontWeight.Medium, [8, 8, 8], [14, 14, 14])}
    `
);
