import styled, { css } from 'styled-components';
import { fluidSizing } from 'src/utils';

export const Links = styled.nav(
    ({ theme }) => css`
        display: none;
        margin: 0 auto;

        ${fluidSizing([
            {
                prop: 'gap',
                values: [10, 20, 20]
            }
        ])}

        ${theme.breakpoints.up('s')} {
            display: flex;
        }
    `
);
