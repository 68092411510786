import React from 'react';

export type TThemedInputContext = {
    borderColor: string;
    color: string;
    backgroundColor: string;
    hoverBorderColor: string;
    hoverColor: string;
    hoverBackgroundColor: string;
    spinnerColor: string;
};

export const ThemedInputContext = React.createContext({} as TThemedInputContext);
