import styled, { css } from 'styled-components';

import { TMenuAnimationOptions } from './OxMenu';
import { transparentize } from 'polished';

export const Container = styled.div<TMenuAnimationOptions>(
    ({ show, animationTime, theme }) => css`
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        color: ${theme.colors.primary.main};
        background: transparent;
        transition-duration: ${animationTime}ms;
        transition-property: background, height;
        transition-delay: 0ms, ${animationTime}ms;
        visibility: hidden;
        height: 0;
        pointer-events: none;
        ${show &&
        css`
            height: calc(var(--vh, 1vh) * 100);
            pointer-events: unset;
            z-index: 50;
            visibility: visible;
            transition-property: background;
            transition-delay: 0ms;
            background: ${({ theme }): string => transparentize(0.2, theme.colors.basic.black)};
        `}
    `
);

export const AnimatedBackground = styled.div<TMenuAnimationOptions>(
    ({ show, theme, animationTime }) => css`
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        height: 0;
        background: ${theme.colors.secondary.main};
        transition: ${animationTime}ms cubic-bezier(0.89, 0.1, 0.26, 0.94);
        z-index: -1;
        overflow: hidden;
        svg {
            position: absolute;
            color: ${theme.colors.basic.ouronyxLogo};
        }
        ${theme.breakpoints.only('xs')} {
            svg {
                width: 75%;
                top: 10%;
                left: 25%;
            }
        }
        ${theme.breakpoints.only('s')} {
            svg {
                width: 35%;
                height: auto;
                top: 2%;
                right: 13%;
            }
        }
        ${theme.breakpoints.up('m')} {
            left: 50%;
            svg {
                width: 92%;
                height: 100vh;
                left: 4%;
                top: 0;
                right: 0;
            }
        }
        ${show &&
        css`
            height: 100%;
        `}
    `
);

export const ContentWrapper = styled.div(
    ({ theme }) => css`
        height: 100%;
        display: grid;
        grid-template-areas: 'MenuNavigation' 'MenuNewsletter' 'MenuSocial';
        grid-template-rows: 1fr auto auto;
        ${theme.breakpoints.up('s')} {
            grid-template-areas:
                'MenuNavigation MenuNavigation'
                'MenuNewsletter MenuSocial';
            grid-template-rows: 75% 25%;
        }
        ${theme.breakpoints.up('m')} {
            grid-template-areas:
                'MenuAsset MenuNavigation MenuNavigation'
                'MenuAsset MenuNewsletter MenuSocial';
            grid-template-rows: 75% 25%;
            grid-template-columns: 50% 25% 25%;
        }
    `
);
