import React, { Props, ReactElement } from 'react';

import { BreakpointProvider } from 'src/hooks';
import Helmet from 'react-helmet';
import { OxRootContainer } from 'src/components/OxRootContainer';
import StyleBase from 'src/styles/base';
import StyleReset from 'src/styles/reset';
import { ThemeProvider } from 'styled-components';
import fonts from 'src/styles/fonts';
import theme from 'src/config/theme';

export default function Layout({ children, location }: Props<ReactElement>): JSX.Element {
    return (
        <ThemeProvider theme={theme}>
            <BreakpointProvider>
                <Helmet defer={false}>
                    <style>{fonts}</style>
                </Helmet>
                <StyleReset />
                <StyleBase />
                <OxRootContainer location={location}>{children}</OxRootContainer>
            </BreakpointProvider>
        </ThemeProvider>
    );
}
