import {
    TAppointment,
    TAppointmentType,
    TCart,
    TClinic,
    TPractitioner,
    TUser
} from 'src/services/api/api.types';

import { DateTime } from 'luxon';
import React, { RefObject } from 'react';
import { TBookingFlowReducerAction } from 'src/components/OxBooking/OxBookingFlowReducer';

export enum EBookingType {
    Virtual = 'virtual',
    Appointment = 'appointment'
}

export type TBookingFlowContextState = {
    processing?: boolean;
    shouldValidateAppointment?: boolean;
    geoipCountry?: string;
    consultationDate?: DateTime | string;
    consultationTime?: DateTime | string;
    currentStage: number;
    consultationLocationId?: string;
    practitioner?: TPractitioner;
    preferredPractitioner?: string;
    practitionerFirstVisit?: boolean;
    appointmentTypeId?: string;
    chooseDoctorForMe?: boolean;
    appointments: TAppointment[];
    appointmentTypes: TAppointmentType[];
    newlyCreatedUser?: boolean;
    userLoggedIn?: boolean;
    client?: TBookingFlowContextClient;
    clinic?: TClinic;
    user?: TUser;
    cart?: TCart;
    isNonZeroValueCart?: boolean;
    fullAppointment: TAppointment | undefined;
    type?: EBookingType;
    paymentDisabled?: boolean;
    paymentObject: TPaymentObject;
    hasPaymentMethods?: boolean;
};

export type TPaymentObject = {
    stripe: any;
    elements: any;
    customerIntent: string;
};

export type TBookingFlowContextClient = {
    isLoggedIn?: boolean;
    firstName?: string | undefined;
    secondName?: string | undefined;
    email?: string | undefined;
    mobile?: string | undefined;
};

export type TBookingFlowContext = {
    reset: () => void;
    paymentDisabled: boolean;
    state: TBookingFlowContextState;
    activeStepId: number;
    setBookingContainerReference: (val: RefObject<HTMLDivElement>) => void;
    scrollToComponentTop: () => void;
    dispatch: React.Dispatch<TBookingFlowReducerAction>;
    goToPrevStep: () => void;
    goToNextStep: () => void;
    goToStep: (stepIndex: number) => void;
};

export const BookingFlowContext = React.createContext({} as TBookingFlowContext);
