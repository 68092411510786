import { EColors, EFontWeight, EFonts } from 'src/config/enums';
import styled, { css } from 'styled-components';

import { OxContainer } from 'src/components/OxContainer';
import { createTextStyle } from 'src/utils/createTextStyle';
import { fluidSizing } from 'src/utils';
import { OxLink } from 'src/components/OxLink';
import { TBasicTheme } from 'src/types/theme.types';
import { OxImage } from 'src/components/OxImage';
import { OxFooterNewsletter } from 'src/components/OxFooterNewsletter';

const border = ({
    theme,
    includeDesktop = false
}: {
    theme: TBasicTheme;
    includeDesktop: boolean;
}) => css`
    border-bottom: 0 solid ${theme.colors.primary.main};

    ${fluidSizing([
        {
            prop: 'border-bottom-width',
            values: [1.5, includeDesktop ? 1.5 : 0, includeDesktop ? 1.5 : 0]
        },
        {
            prop: 'margin-bottom',
            values: [-15, 0, 0]
        },
        {
            prop: 'padding-bottom',
            values: [15, includeDesktop ? 20 : 0, includeDesktop ? 30 : 0]
        }
    ])}
`;

export const Container = styled(OxContainer).attrs({ as: 'footer' })(
    ({ theme }) => css`
        background-color: ${theme.colors.secondary.main};
        color: ${theme.colors.primary.main};
        display: grid;
        grid-template-columns: 100%;
        grid-template-areas:
            'FooterIcon'
            'FooterNavigation'
            'FooterSubscribe'
            'FooterSocial'
            'FooterRegulated'
            'FooterCopyrights';
        ${fluidSizing([
            { prop: 'row-gap', values: [30, 20, 30] },
            { prop: 'padding-top', values: [25, 40, 60] },
            { prop: 'padding-bottom', values: [25, 40, 60] }
        ])}

        ${createTextStyle(EFonts.Montserrat, EFontWeight.Medium, [16, 10, 10], [45, 18, 18])}

    ${theme.breakpoints.up('s')} {
            grid-template-areas:
                'FooterNavigation FooterIcon FooterSubscribe'
                'FooterRegulated FooterRegulated FooterSocial'
                'FooterCopyrights FooterCopyrights FooterCopyrights';
            grid-template-columns: 1fr auto 1fr;
        }
        @media print {
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
    `
);

export const Icon = styled.div(
    ({ theme }) => css`
        align-items: flex-start;
        display: flex;
        grid-area: FooterIcon;
        justify-content: center;
        svg {
            ${fluidSizing([{ prop: 'width', values: [56, 49, 60] }])}

            @media print {
                ${fluidSizing([{ prop: 'width', values: [25, 25, 30] }])}
            }
        }
        ${theme.breakpoints.up('s')} {
            text-align: center;
            margin: 0 5rem;
        }
        @media print {
            text-align: center;
        }
    `
);

export const Locations = styled.div(
    ({ theme }) => css`
        align-items: center;
        display: flex;
        flex-wrap: wrap;
        grid-area: FooterSocial;
        justify-content: center;
        text-align: center;
        ${theme.breakpoints.up('s')} {
            align-items: flex-end;
            justify-content: flex-start;
            text-align: left;
        }

        ${border({ theme, includeDesktop: true })}

        h4 {
            display: none;
            flex: 0 0 100%;
            ${createTextStyle(EFonts.Hatton, EFontWeight.Medium, [12, 12, 12], [16, 16, 16])}
            ${fluidSizing([
                { prop: 'font-size', values: [20, 20, 20] },
                { prop: 'line-height', values: [24, 24, 24] },
                { prop: 'margin-bottom', values: [5, 5, 8] }
            ])}

      ${theme.breakpoints.up('s')} {
                display: block;
            }
        }
    `
);

export const Location = styled.div(
    ({ theme }) => css`
        width: 100%;
        flex: 1;

        ${theme.breakpoints.up('s')} {
            width: unset;
        }
    `
);
export const LocationName = styled.div`
    text-transform: uppercase;
`;
export const LocationAddress = styled.div`
    p {
        ${fluidSizing([
            { prop: 'font-size', values: [14, 10, 10] },
            { prop: 'line-height', values: [30, 20, 20] }
        ])}
    }
`;

export const LocationLink = styled(OxLink)(
    ({ theme }) => css`
        margin-left: auto;
        margin-right: auto;
        ${theme.breakpoints.up('s')} {
            margin-left: 0;
        }
        ${fluidSizing([
            { prop: 'font-size', values: [14, 10, 10] },
            { prop: 'line-height', values: [30, 20, 20] }
        ])}
    `
);

export const WhatappLink = styled(OxLink)(
    ({ theme }) => css`
        margin-left: auto;
        margin-right: auto;
        ${theme.breakpoints.up('s')} {
            margin-left: 0;
            margin-top: 10px;
        }
    `
);

export const Navigation = styled.nav(
    ({ theme }) => css`
        grid-area: FooterNavigation;
        display: flex;
        flex-direction: column;
        text-align: center;
        ul {
            ${theme.breakpoints.up('s')} {
                ${fluidSizing([{ prop: 'margin-right', values: [40, 40, 40] }])}
            }

            li {
                ${fluidSizing([{ prop: 'margin-bottom', values: [10, 10, 10] }])}

                a,
        button {
                    font-size: inherit;
                    margin-left: auto;
                    margin-right: auto;
                    ${theme.breakpoints.up('s')} {
                        margin-left: unset;
                    }
                }
            }

            &:last-of-type {
                li {
                    &:last-of-type {
                        margin-bottom: 0;
                    }
                }
            }
        }
        ${theme.breakpoints.up('s')} {
            flex-direction: row;
            text-align: left;
        }

        ${border({ theme })}
    `
);

export const Regulated = styled.div(
    ({ theme }) => css`
        align-items: center;
        grid-area: FooterRegulated;
        margin: 0 auto;
        width: 100%;

        ${theme.breakpoints.up('s')} {
            margin: unset;
            display: flex;
            align-items: flex-start;
        }

        ${fluidSizing([{ prop: 'gap', values: [0, 10, 10] }])}

        ${border({ theme, includeDesktop: true })}
    `
);

export const RegulatedSection = styled.div(
    ({ theme }) => css`
        align-items: center;
        display: flex;
        flex-wrap: wrap;
        flex: 1 1 100%;
        justify-content: space-between;
        column-gap: 1rem;
        row-gap: 1rem;

        ${theme.breakpoints.up('s')} {
            justify-content: flex-start;
            max-width: 45rem;
        }
    `
);

export const RegulatedItem = styled.div(
    () => css`
        align-items: center;
        display: flex;
        justify-content: left;
        flex: 1 1 35%;

        ${fluidSizing([{ prop: 'gap', values: [10, 10, 10] }])}
    `
);

export const RegulatedItemLeft = styled(RegulatedItem)(() => css``);
export const RegulatedItemRight = styled(RegulatedItem)(
    () =>
        css`
            align-self: flex-end;
        `
);

export const ImgWrapper = styled(OxLink)<{ widths: number[] }>(
    ({ widths }) => css`
        align-items: center;
        display: flex;
        flex-grow: 0;
        flex-shrink: 0;
        justify-content: center;

        ${fluidSizing([{ prop: 'flex-basis', values: widths }])}

        & > * {
            width: 100%;
        }
    `
);

export const Img = styled(OxImage)<{ widths: number[] }>(
    ({ widths }) => css`
        ${fluidSizing([{ prop: 'width', values: widths }])}

        & > * {
            width: 100%;
        }
    `
);

export const RegulatedText = styled.div`
    ${fluidSizing([{ prop: 'font-size', values: [9, 9, 9] }])}
`;

export const Copyrights = styled.div(
    () => css`
        grid-area: FooterCopyrights;
        text-align: center;

        @media print {
            text-align: center;
            ${createTextStyle(EFonts.Montserrat, EFontWeight.Medium, [6, 6, 6], [18, 18, 18])}
        }
    `
);

export const Newsletter = styled(OxFooterNewsletter)(
    ({ theme }) => css`
        ${border({ theme })}

        margin-top: 2rem;
        ${theme.breakpoints.up('s')} {
            margin-top: 0;
        }
    `
);
