import { StripeCardElementOptions } from '@stripe/stripe-js';
import theme from 'src/config/theme';

export const API_ENDPOINT = process.env.GATSBY_MIDDLEWARE_API_ENDPOINT;
export const WS_ENDPOINT = process.env.GATSBY_MIDDLEWARE_WS_ENDPOINT;
export const CLINIKO_SITE_URL_LONDON = process.env.GATSBY_CLINIKO_SITE_URL_LONDON;
export const CLINIKO_SITE_URL_DUBAI = process.env.GATSBY_CLINIKO_SITE_URL_DUBAI;
export const TP_LIVESTATUS = process.env.GATSBY_TP_LIVESTATUS;
export const PORTAL_ID = '8101497';
export const NEWSLETTER_PORTAL_ID = PORTAL_ID;
export const NEWSLETTER_FORM_GUID = 'fe159239-724a-482e-863d-b066f16adbb7';
export const CALLBACK_PORTAL_ID = PORTAL_ID;
export const CALLBACK_FORM_GUID = 'd6de6f26-5785-40a8-97d9-8913de4b649f';
export const VIRTUAL_CONSULTATION_PORTAL_ID = PORTAL_ID;
export const VIRTUAL_CONSULTATION_FORM_GUID = 'b36d36c3-7e3e-461f-b76f-08311bf1357e';
export const FEEDBACK_PORTAL_ID = PORTAL_ID;
export const FEEDBACK_FORM_GUID = 'cbff2d63-7dc4-4ae8-829c-6863cb13c518';
export const BOOKING_PORTAL_ID = PORTAL_ID;
export const BOOKING_FORM_GUID = 'a524813b-ef5c-489b-b81a-9db5f04daaa8';
export const CONTACT_PORTAL_ID = PORTAL_ID;
export const CONTACT_FORM_GUID = 'fa943d09-5ed9-44bc-aaab-956281b764c0';
export const CONVERSATION_PORTAL_ID = PORTAL_ID;
export const CONVERSATION_FORM_GUID = '13be77f9-f80c-4942-a485-fff61ddd5c3c';
export const SUBSCRIBE_BLOCK_ID = PORTAL_ID;
export const SUBSCRIBE_BLOCK_GUID = '4c2b1512-8085-4c93-b6a1-b1614e7987a0';
export const TINY_MCE_API_KEY = '3brqdv489blclmz7k4y8zff77i19ajgdqja6usvd3w1lm56a';

export const SOCIAL_INSTAGRAM = 'https://www.instagram.com/Ouronyx/';
export const SOCIAL_FACEBOOK = 'https://www.facebook.com/Ouronyx';
export const SOCIAL_YOUTUBE = 'https://www.youtube.com/channel/UC0Y3sRkxwuRg7NSBUvCGecA';
export const SOCIAL_TWITTER = 'https://twitter.com/OuronyxO';
export const SOCIAL_LINKEDIN = 'https://www.linkedin.com/company/ouronyx';

// OURONYX_MVP2020_WEBSITE_HEADER_CLOSE%20UP_960x960_COL.mov
export const VIDEO_WEBSITE_HEADER_SMALL_ID = '95FmUmPLkKASlJ1nC38CJwmiwzZFBC2kk7oFdUaay68';

// OURONYX_MVP2020_WEBSITE_HEADER_CLOSE%20UP_2560x1310_COL.mov
export const VIDEO_WEBSITE_HEADER_LARGE_ID = 'rlFxacgYVKPxvkhr3RgpfQXnpOFbTPHm02vAdC00NJuEA';

// OURONYX_MVP2020_GIF1_CAROLINE_960x660_COL.mov
export const VIDEO_CAMPAIGN_SHORT_SMALL_ID = 'x02zFH8A00Uh2jWOUBrOvbZcgQZt7C9RWFc9PEorut7cY';

// OURONYX_MVP2020_GIF1_CAROLINE_1536x1566_COL.mov
export const VIDEO_CAMPAIGN_SHORT_LARGE_ID = 'JKTAmFIP4wnvdbPk7CRyEBmMNqSIyHD8K9r01VPZb1pY';

// OURONYX_MVP2020_HERO_FILM_960x960_redelivery_16042021.mp4
export const VIDEO_HERO_FILM_SMALL_ID = 'txkq6ehgawO02v6m172Hxc2GRzYzEVy3ECiyrkx02R301Q';

// OURONYX_MVP2020_HERO_FILM_2560x1310_redelivery_16042021.mp4
export const VIDEO_HERO_FILM_LARGE = 'trJv9RwhTY1WC8Fpgj9x6hZRZIlwAoUoOX6uzTlkgaM';

// iman_OURONYX_MVP2020_INTERVIEW_FILM30_DOCTOR1_960x960.mp4
export const VIDEO_BIO_IMAN_SMALL_ID = '7qr8bwod45W4Yshk9N7ydWpStSAeDjbScIV8Uv9foXE';

// iman_OURONYX_MVP2020_INTERVIEW_FILM30_DOCTOR1_2560x1310.mp4
export const VIDEO_BIO_IMAN_LARGE_ID = 'ursJe2HwraiRRH00p3pN7yikm9sow00v4qhiCVsOotphI';

// raj_OURONYX_MVP2020_INTERVIEW_FILM30_DOCTOR2_960x960.mp4
export const VIDEO_BIO_RAJ_SMALL_ID = 'oNI8pjRvzUp8zgf5XJstO018DjCZ00a4HgSKHT02HjBE02I';

// raj_OURONYX_MVP2020_INTERVIEW_FILM30_DOCTOR2_2560x1310.mp4
export const VIDEO_BIO_RAJ_LARGE_ID = 'DN00WhrmfevdIVlv006RuXFwy1QdIWkr6p502LHJ8tlFvk';

// sabika_OURONYX_MVP2020_INTERVIEW_FILM30_DOCTOR3_960x960.mp4
export const VIDEO_BIO_SABIKA_SMALL_ID = 'i8OpO00N2hsm02I9HwzhcXFgW0202ekdWCYErhh02CBzw8hc';

// sabika_OURONYX_MVP2020_INTERVIEW_FILM30_DOCTOR3_2560x1310.mp4
export const VIDEO_BIO_SABIKA_LARGE_ID = 'JHF97f6vk502qeDlEdYjuLs01BxSCbp1RTJN2jMwMyb9Q';

// OURONYX_MVP2020_DOES_BEAUTY_HAVE_POWER_960x960_redelivery_16042021.mp4
export const VIDEO_STORY_ADVISORY1_SMALL_ID = 'GSvQY11BFztmWDWZ7MQK43pkbWSkkqWIb1jeD00ZYSwg';

// OURONYX_MVP2020_DOES_BEAUTY_HAVE_POWER_2560x1310_02.mp4
export const VIDEO_STORY_ADVISORY1_LARGE_ID = '78R7NWgm4mDSAbCM00Pv6Wwp5L4ATXU88019s6s1wqFjA';

// OURONYX_MVP2020_Q&A_02_DOCTOR_2_960x960.mov
export const VIDEO_STORY_ADVISORY2_SMALL_ID = 'Jfouklh1j43m1HOb6v02tmDidamzDVj00ZyzT021qnCgbk';

// OURONYX_MVP2020_Q&A_02_DOCTOR_2_2560x1310.mov
export const VIDEO_STORY_ADVISORY2_LARGE_ID = 'pLuGnOYRMFNKxnwhcYC302zzqWmlxRZZTn91rt3g00KjU';

// OURONYX_MVP2020_Q&A_01_DOCTOR_3_960x960.mov
export const VIDEO_STORY_ADVISORY3_SMALL_ID = 'qKbPZyNbLbt4BOL1JCz3ab2uf00FMdtHQTkciy889Tbg';

// OURONYX_MVP2020_Q&A_01_DOCTOR_3_2560x1310.mov
export const VIDEO_STORY_ADVISORY3_LARGE_ID = 'eGzRN9ZtFVbHcUCw7GT56THPb7rkYUfPToS5gBjdJdc';

export const VIDEO_EXPERIENCE_SMALL_ID = 'KeM7017jH00brbQSu7jh5IXsaF3vEAdvX6oSxC5A9902ig';
export const VIDEO_EXPERIENCE_LARGE_ID = 'KRG9Pp0001T9uy00YCu3nN02OhSgWel2Onyrzk8N9IGHYgQ';

// Not used for security
export const STAFF_PIN = '2020';

export const STRIPE_CARD_OPTIONS: StripeCardElementOptions = {
    iconStyle: 'solid',
    style: {
        base: {
            iconColor: theme.colors.primary.main,
            color: theme.colors.primary.main,
            fontWeight: 500,
            fontFamily: theme.fonts.secondary,
            fontSize: '16px',
            fontSmoothing: 'antialiased',
            ':-webkit-autofill': {
                color: theme.colors.primary.main
            },
            '::placeholder': {
                color: theme.colors.primary.main
            }
        },
        invalid: {
            iconColor: theme.colors.basic.darkRed,
            color: theme.colors.basic.darkRed
        }
    }
};
