import { fluidSizing, toVw } from 'src/utils';
import styled, { css } from 'styled-components';

import { OxContainer } from 'src/components/OxContainer';
import { OxLink } from 'src/components/OxLink';
import { transparentize } from 'polished';
import { OxButton } from 'src/components/OxButton';
import { EFonts, EFontWeight } from 'src/config/enums';
import { createTextStyle } from 'src/utils/createTextStyle';
import { ESizes } from 'src/config/enums';
import { OxGetInTouch } from 'src/components/OxGetInTouch';

export const Header = styled(OxContainer).attrs({
    as: 'header'
})(
    ({ theme }) => css`
        top: 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: sticky;
        z-index: 100;
        background: ${transparentize(0.1, theme.colors.basic.white)};
        ${fluidSizing([
            {
                prop: 'height',
                values: [ESizes.NavbarHeight, ESizes.NavbarHeight, ESizes.NavbarHeight]
            }
        ])}

        @media print {
            position: relative;
        }
    `
);

export const Logo = styled(OxLink)(
    ({ theme }) => css`
        ${fluidSizing([{ prop: 'height', values: [20, 25, 25] }])}
        color: ${theme.colors.primary.main};
        svg {
            height: 100%;
            width: auto;
        }

        @media print {
            margin-left: auto;
            margin-right: auto;
        }
    `
);

export const Profile = styled(OxLink)(
    ({ theme }) => css`
        margin-left: auto;
        transition: color 0.4s ease-out;
        ${fluidSizing([{ prop: 'height', values: [25, 25, 25] }])}
        color: ${theme.colors.primary.main};
        &:hover {
            color: ${theme.colors.basic.black};
        }
        svg {
            height: 100%;
            width: auto;
        }
        ${theme.breakpoints.only('xs')} {
            display: none;
        }
        ${theme.breakpoints.up('s')} {
            margin-left: 0;
        }
    `
);

export const NavButton = styled.button<{ isOpen: boolean }>(
    ({ theme, isOpen }) => css`
        display: block;
        position: relative;
        z-index: 100;

        ${theme.breakpoints.up('s')} {
            display: none;
        }

        ${fluidSizing([
            { prop: 'width', values: [20, 20, 20] },
            { prop: 'height', values: [10, 10, 10] }
        ])}

        &:hover span:before {
            transform: scaleX(1);
            transform-origin: left;
        }

        span {
            display: block;
            position: relative;
            width: 100%;
            height: 1px;
            transition: all 0.4s ease-out;
            background: ${theme.colors.primary.main};
            &:nth-child(2) {
                width: 50%;
                ${fluidSizing([
                    { prop: 'margin-top', values: [5, 5, 5] },
                    { prop: 'margin-bottom', values: [5, 5, 5] }
                ])}
            }
            &:before {
                left: 0;
                top: 0;
                width: 100%;
                height: 1px;
                content: '';
                position: absolute;
                background: ${theme.colors.basic.black};
                transform: scaleX(0);
                transform-origin: right;
                transition: transform 0.4s ease-out;
            }
        }

        ${isOpen &&
        css`
            /* span:before { */
            /* transform: scaleX(1); */
            /* transform-origin: left; */
            /* } */
            span:nth-child(1) {
                ${theme.breakpoints.only('xs')} {
                    transform: ${`rotate(135deg) translate(${toVw(5, 'xs')}, ${toVw(-3, 'xs')});`};
                }
                ${theme.breakpoints.only('s')} {
                    transform: ${`rotate(135deg) translate(${toVw(5, 's')}, ${toVw(-3, 's')});`};
                }
                ${theme.breakpoints.up('m')} {
                    transform: ${`rotate(135deg) translate(${toVw(5, 'm')}, ${toVw(-3, 'm')});`};
                }
            }
            span:nth-child(2) {
                opacity: 0;
            }
            span:nth-child(3) {
                ${theme.breakpoints.only('xs')} {
                    transform: ${`rotate(45deg) translate(${toVw(-5, 'xs')}, ${toVw(-3, 'xs')});`};
                }
                ${theme.breakpoints.only('s')} {
                    transform: ${`rotate(45deg) translate(${toVw(-5, 's')}, ${toVw(-3, 's')});`};
                }
                ${theme.breakpoints.up('m')} {
                    transform: ${`rotate(45deg) translate(${toVw(-5, 'm')}, ${toVw(-3, 'm')});`};
                }
            }
        `}
    `
);

export const Social = styled(OxGetInTouch)(
    ({ theme }) => css`
        color: ${theme.colors.primary.main};
        margin-left: auto;

        ${theme.breakpoints.up('s')} {
            margin-left: unset;
        }
    `
);

export const ConsultationButton = styled(OxButton)`
    display: block;
    text-transform: uppercase;

    ${createTextStyle(EFonts.Montserrat, EFontWeight.Medium, [8, 8, 8], [16, 16, 16])}
    ${fluidSizing([
        { prop: 'height', values: [20, 25, 25] },
        { prop: 'margin-left', values: [0, 20, 20] },
        { prop: 'margin-right', values: [0, 20, 20] },
        { prop: 'min-width', values: [50, 50, 50] }
    ])}
`;

export const ContactButton = styled(OxButton)(
    ({ theme }) => css`
        display: none;
        text-transform: uppercase;

        ${createTextStyle(EFonts.Montserrat, EFontWeight.Medium, [8, 8, 8], [16, 16, 16])}
        ${fluidSizing([
            { prop: 'height', values: [20, 25, 25] },
            { prop: 'min-width', values: [50, 50, 50] }
        ])}

  ${theme.breakpoints.only('xs')} {
            display: block;
        }
    `
);
