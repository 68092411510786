import { TBillable, TCart, TConfig } from './api.types';
import { apiGetCall, apiPostCall } from './api.core';

import { EApiEndpoint } from 'src/config/enums';
import { applyValues } from 'src/utils/applyValues';
import { CONFIG_PATH, getScopedConfig } from 'src/services/api/config';

type TCheckoutBillableData = {
    clinicId: string;
    appointmentTypeId: string;
};

type TCheckoutCartData = {
    clinicId: number;
    appointmentTypeId: string;
    startsAt: string;
    practitionerId: string;
    preferredPractitionerId?: string;
};

type TCheckoutCartAddressData = {
    type?: string;
    prefix?: string;
    firstname?: string;
    middlename?: string;
    lastname?: string;
    street1?: string;
    city?: string;
    postcode?: string;
    country?: string;
};

export type TCheckoutPlaceOrder = {
    id?: string;
    payment_method?: string;
};

type TPlaceOrderError = {
    reason: string;
    status: number;
    title: string;
};

export const checkoutGetBillable = async (
    values: TCheckoutBillableData
): Promise<TBillable[] | null> => {
    return await apiGetCall(applyValues(EApiEndpoint.GetBillable, values));
};

export const checkoutPostCart = async (values: TCheckoutCartData): Promise<TCart[] | null> => {
    return await apiPostCall(EApiEndpoint.PostCart, JSON.stringify(values), {
        credentials: true
    });
};

export const checkoutCartAddress = async (
    values: TCheckoutCartAddressData
): Promise<TCart | null> => {
    return await apiPostCall(EApiEndpoint.CheckoutCartAddress, JSON.stringify(values), {
        credentials: true
    });
};

export const initPayment = async (): Promise<{
    secret: string;
} | null> => {
    return await apiPostCall(EApiEndpoint.CheckoutPayment, undefined, {
        credentials: true
    });
};

export const checkoutPlaceOrder = async (
    values: TCheckoutPlaceOrder
): Promise<(TCart & TPlaceOrderError) | null> => {
    return await apiPostCall(EApiEndpoint.CheckoutPlaceOrder, JSON.stringify(values), {
        credentials: true
    });
};

export const getPaymentCaptureRequired = async (clinicId: number): Promise<TConfig | null> => {
    return getScopedConfig(CONFIG_PATH.CHECKOUT_PAYMENT_REQUIRED, clinicId);
};
