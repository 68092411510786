import { EventEmitter } from 'fbemitter';
import { DateTime, Duration } from 'luxon';

const LOCALSTORAGE_KEY = 'ouronyx_api_cache';
const LOCALSTORAGE_VERSION_KEY = 'ouronyx_api_cache_version';
const VERSION = process.env.GATSBY_DEPLOY_ID ?? 'NO_VERSION';
const CACHE_LIFETIME = Duration.fromObject({ day: 1 });
type TCacheEntry = {
    timestamp: string;
    data: Record<string, unknown>;
};

type TCache = {
    [key: string]: TCacheEntry;
};

const cacheableCalls: { [key: string]: EventEmitter } = {};

const getStorageKey = (secure?: boolean): string =>
    `${LOCALSTORAGE_KEY}${secure ? '_private' : ''}_${VERSION}`;

const jsStorage: Record<string, string> = {};

const getStorageItem = (key: string) => {
    try {
        return localStorage.getItem(key);
    } catch (e) {
        console.error('Cannot access local storage');
        return jsStorage[key] ?? '';
    }
};

const setStorageItem = (key: string, value: string) => {
    try {
        return localStorage.getItem(key, value);
    } catch (e) {
        console.error('Cannot access local storage');
        jsStorage[key] = value;
    }
};

const removeStorageItem = (key: string) => {
    try {
        return localStorage.removeItem(key);
    } catch (e) {
        console.error('Cannot access local storage');
        delete jsStorage[key];
    }
};

const getCache = (secure?: boolean): TCache => {
    return JSON.parse(getStorageItem(getStorageKey(secure)) ?? '{}');
};

const setCache = (newCache: TCache, secure?: boolean): void => {
    setStorageItem(getStorageKey(secure), JSON.stringify(newCache));
};

const isExpired = (cacheEntry: TCacheEntry): boolean => {
    return (
        DateTime.fromISO(cacheEntry.timestamp).plus(CACHE_LIFETIME).diffNow('second').seconds < 0
    );
};

const isNewBuild = (): boolean => {
    if (getStorageItem(LOCALSTORAGE_VERSION_KEY) !== VERSION) {
        for (const key in localStorage) {
            if (
                key.startsWith(LOCALSTORAGE_KEY) &&
                key !== LOCALSTORAGE_VERSION_KEY &&
                key !== getStorageKey() &&
                key !== getStorageKey(true)
            ) {
                removeStorageItem(key);
            }
        }

        setStorageItem(LOCALSTORAGE_VERSION_KEY, VERSION);

        return true;
    } else {
        return false;
    }
};

export const clearSecureCache = (): void => {
    removeStorageItem(getStorageKey(true));
};

export const clearCache = (): void => {
    removeStorageItem(getStorageKey());
};

export const clearAllCaches = (): void => {
    clearCache();
    clearSecureCache();
};

type TCachedData<P> = P | null;

export const getFromCache = async (
    endpoint: string,
    secure?: boolean
): Promise<TCachedData<unknown>> => {
    if (cacheableCalls[endpoint]) {
        return await new Promise((resolve) => {
            cacheableCalls[endpoint].once('resolve', (data: Record<string, unknown>) => {
                resolve(data);
            });
        });
    } else {
        const cache = getCache(secure);

        if (!cache[endpoint] || isExpired(cache[endpoint]) || isNewBuild()) {
            cacheableCalls[endpoint] = new EventEmitter();
            return null;
        }

        return cache[endpoint].data;
    }
};

export const saveToCache = (
    endpoint: string,
    data: Record<string, unknown>,
    secure?: boolean
): void => {
    const cache = getCache(secure);
    cache[endpoint] = {
        timestamp: DateTime.local().toISO(),
        data
    };
    setCache(cache, secure);

    if (cacheableCalls[endpoint]) {
        cacheableCalls[endpoint].emit('resolve', data);
        delete cacheableCalls[endpoint];
    }
};
