import styled, { css, keyframes } from 'styled-components';

import { fluidSizing } from 'src/utils';

const animation = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const animationWithTranslate = keyframes`
  0% { transform: translate(-50%, -50%) rotate(0deg); }
  100% { transform: translate(-50%, -50%) rotate(360deg); }
`;

export const OxSpinner = styled.div<{ addTranslate?: boolean }>(
    ({ theme, addTranslate }) => css`
        border-radius: 50%;
        animation: ${addTranslate ? animationWithTranslate : animation} 1000ms linear infinite;
        ${fluidSizing([
            { prop: 'width', values: [60, 60, 60] },
            { prop: 'height', values: [60, 60, 60] },
            {
                prop: 'border',
                values: [6, 6, 6],
                formatter: (val): string => `${val}vw solid ${theme.colors.secondary.main}`
            },
            {
                prop: 'border-top',
                values: [6, 6, 6],
                formatter: (val): string => `${val}vw solid ${theme.colors.primary.main}`
            }
        ])}
    `
);
