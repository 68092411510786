import styled, { css } from 'styled-components';
import { Link } from 'gatsby';
import { hoverUnderlineAnimation } from 'src/styles/animations';
import { TBasicTheme } from 'src/types/theme.types';
import { EColors } from 'src/config/enums';
import { TThemedInputContext } from 'src/context/ThemedInputContext';

const sharedStyles = ({
    $customTheme,
    theme,
    $withUnderlineAnimation
}: {
    $customTheme: TThemedInputContext;
    theme: TBasicTheme;
    $withUnderlineAnimation?: boolean;
    backgroundColor?: EColors;
}) => css`
    ${$withUnderlineAnimation &&
    css`
        ${hoverUnderlineAnimation({ theme }, $customTheme.color as EColors)}
        width: fit-content;
    `}
`;

export const GatsbyLink = styled(Link)<{
    $customTheme: TThemedInputContext;
    $withUnderlineAnimation?: boolean;
}>(
    ({ $customTheme, theme, $withUnderlineAnimation }) => css`
        ${sharedStyles({ $customTheme, theme, $withUnderlineAnimation })};
    `
);

export const RegularLink = styled.a<{
    $customTheme: TThemedInputContext;
    $withUnderlineAnimation?: boolean;
}>(
    ({ $customTheme, theme, $withUnderlineAnimation }) => css`
        ${sharedStyles({ $customTheme, theme, $withUnderlineAnimation })};
    `
);

export const Button = styled.button<{
    $customTheme: TThemedInputContext;
    $withUnderlineAnimation?: boolean;
}>(
    ({ $customTheme, theme, $withUnderlineAnimation }) => css`
        ${sharedStyles({ $customTheme, theme, $withUnderlineAnimation })};
    `
);
