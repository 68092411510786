import * as Styled from './OxHygyene.styled';

import React, { createRef, useContext, useEffect, useRef, useState } from 'react';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { graphql, useStaticQuery } from 'gatsby';

import { LayoutContext } from 'src/context/LayoutContext';
import { OxHygenePoint } from 'src/components/OxHygene/components';

export enum EModalId {
    Privacy = 'privacy',
    Cookies = 'cookies',
    Terms = 'terms',
    Cancellation = 'cancellation'
}

type THygyenePoint = {
    order: number;
    title: string;
    description: string;
};

export type THygyeneItem = {
    id: string;
    page_title: string;
    points: THygyenePoint[];
};

export const OxHygyene = (): JSX.Element => {
    const modalContentRef = useRef<HTMLDivElement>();
    const modalRef = createRef<HTMLDivElement>();
    const { hygyene } = useStaticQuery(
        graphql`
            query {
                hygyene: allSanityHygyene {
                    nodes {
                        contentTitle
                        identifier
                        pageTitle
                        points {
                            title
                            order
                            description
                        }
                    }
                }
            }
        `
    );

    // const hygyene = { nodes: [] };

    const [content, setContent] = useState(null);
    const layoutContext = useContext(LayoutContext);

    useEffect(() => {
        setContent(
            hygyene.nodes.find((item: THygyeneItem) => item.identifier === layoutContext.modalId)
        );

        if (!layoutContext.modalId) {
            enableBodyScroll(modalRef.current);
        } else {
            disableBodyScroll(modalRef.current);
        }
    }, [layoutContext.modalId]);

    const handleClickOutside = (event) => {
        if (!modalContentRef.current?.contains(event.target)) {
            layoutContext.setModalId(null);
        }
    };

    useEffect(() => {
        if (layoutContext.modalId) {
            document.addEventListener('mousedown', handleClickOutside);
        }
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    });

    return (
        <Styled.Modal show={!!content}>
            <Styled.ModalOverlay ref={modalRef}>
                {content && (
                    <Styled.ModalContent ref={modalContentRef}>
                        <Styled.LogoContainer>
                            <Styled.Logo name="ouronyx-icon" />
                            <Styled.CloseIcon
                                name="close"
                                onClick={() => layoutContext.setModalId(null)}
                            />
                        </Styled.LogoContainer>
                        <Styled.ContentContainer>
                            <Styled.PageTitle>{content.pageTitle}</Styled.PageTitle>
                            <Styled.Content>
                                {content.contentTitle && (
                                    <Styled.ContentTitle>
                                        {content.contentTitle}
                                    </Styled.ContentTitle>
                                )}
                                {content.points.map(
                                    ({ description, order, title }, index: number) => (
                                        <OxHygenePoint
                                            order={order}
                                            key={index}
                                            title={title}
                                            description={description}
                                        />
                                    )
                                )}
                                <Styled.Close onClick={() => layoutContext.setModalId(null)}>
                                    Close
                                </Styled.Close>
                            </Styled.Content>
                        </Styled.ContentContainer>
                    </Styled.ModalContent>
                )}
            </Styled.ModalOverlay>
        </Styled.Modal>
    );
};
