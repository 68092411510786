import { EColors, EFonts, EFontWeight } from './enums';

import { TBasicTheme } from 'src/types/theme.types';
import createBreakpoints from 'src/styles/createBreakpoints';
import createSupport from 'src/styles/createSupport';

const theme: TBasicTheme = {
    support: createSupport(),
    breakpoints: createBreakpoints(),
    colors: {
        basic: {
            black: EColors.NaturalBlack,
            white: EColors.White,
            ouronyxLogo: EColors.OuronyxLogo,
            disabledTile: EColors.DisabledTile,
            goldenLight: EColors.GoldenLight,
            lightOatmeal: EColors.LightOatmeal,
            darkOatmeal: EColors.DarkOatmeal,
            lightPanel: EColors.LightPanel,
            darkRed: EColors.DarkRed,
            silverChalice: EColors.SilverChalice,
            springWood: EColors.SpringWood,
            alabaster: EColors.Alabaster,
            silverChalicer: EColors.SilverChalicer,
            wildSand: EColors.WildSand,
            swissCoffee: EColors.SwissCoffee
        },
        primary: {
            main: EColors.Gold,
            variant: EColors.GoldenSand
        },
        secondary: {
            main: EColors.SpringWood,
            variant: EColors.Cameo
        },
        archetype: {
            red: EColors.ArchetypeRed,
            green: EColors.ArchetypeGreen,
            blue: EColors.ArchetypeBlue,
            yellow: EColors.ArchetypeYellow
        }
    },
    fontSizes: {
        // Read more in docs/styles.md
        // Breakpoints: [XS, S, M, L, XL]
        main: [1, 1, 1.4, 1.4, 1.6],
        menuNavigation: [2, 3, 2, 2, 2]
    },
    fontWeights: {
        extraLight: EFontWeight.ExtraLight,
        light: EFontWeight.Light,
        normal: EFontWeight.Normal,
        medium: EFontWeight.Medium,
        semiBold: EFontWeight.SemiBold,
        bold: EFontWeight.Bold
    },
    fonts: {
        main: EFonts.Hatton,
        secondary: EFonts.Montserrat
    }
};

export default theme;
