import { fluidSizing } from 'src/utils/fluidSizing';

type TImgSizesConfig = {
    values: (number | null)[];
    formatter?: (val: number, breakpoint: string) => string;
};

const getRegexpForString = (val: string) => new RegExp(val, 'g');

// Bit hacky...
export const generateImgSizes = (config: TImgSizesConfig): string => {
    const tempPropString = 'tempPropString';
    const fluidSize = fluidSizing([{ ...config, prop: tempPropString }]);

    // We've got to strip out the CSS related strings and wrap the media query in brackets
    const formattedString = fluidSize
        .replace(getRegexpForString(` {${tempPropString}:`), ') ')
        .replace(getRegexpForString(' ;}'), ', ')
        .replace(getRegexpForString('@media '), '(');

    // And remove the ', ' from the end
    return formattedString.substring(0, formattedString.length - 2);
};
