import { EBreakpoints } from 'src/config/enums';
import { TBreakpoint } from 'src/types/styles.types';
import { TContext } from 'src/hooks/useBreakpoints';
import theme from 'src/config/theme';

export enum EUnits {
    VW = 'vw',
    PC = '%'
}

type TSizingConfig = {
    prop: string;
    values: (number | null)[];
    units?: EUnits;
    important?: boolean;
    formatter?: (val: number, breakpoint: string) => string;
};

const breakpointsValues = new Map([
    ['xs', EBreakpoints.ExtraSmall],
    ['s', EBreakpoints.Small],
    ['m', EBreakpoints.Medium],
    ['l', EBreakpoints.Large],
    ['xl', EBreakpoints.ExtraLarge]
]);

const calcVw = (value: number, breakpoint = 'xs'): number => {
    const breakpointValue = breakpointsValues.get(breakpoint);
    if (!breakpointValue) {
        throw new Error('Incorrect breakpoint');
    }
    return parseFloat((value / (breakpointValue / 100)).toFixed(5));
};

export const toVw = (value: number, breakpoint = 'xs'): string => {
    return calcVw(value, breakpoint) + 'vw';
};

const calcPc = (value: number): number => {
    if (0 <= value && value <= 1) {
        return value * 100;
    } else if (value <= 100) {
        return value;
    }

    throw new Error('Value must be between 0 and 1, or 1 and 100');
};

export const toPc = (value: number): string => {
    return calcPc(value) + '%';
};

export const toPx = (value: number, device: TContext): number => {
    const breakpoints = {
        xs: EBreakpoints.ExtraSmall,
        s: EBreakpoints.Small,
        m: EBreakpoints.Medium,
        l: EBreakpoints.Large,
        xl: EBreakpoints.ExtraLarge
    };
    const activeKey = Object.keys(device).find((key) => !!device[key]);
    const vw = parseFloat(toVw(value, activeKey).replace('vw', ''));
    if (!activeKey) return 0;
    return Math.round((breakpoints[activeKey] / 100) * vw);
};

export const fluidSizing = (properties: TSizingConfig[]): string => {
    let result = ``;
    const unit = EUnits.VW;
    const breakpoints = {
        xs: EBreakpoints.ExtraSmall,
        s: EBreakpoints.Small,
        m: EBreakpoints.Medium,
        l: EBreakpoints.Large,
        xl: EBreakpoints.ExtraLarge
    };

    // @silon
    // Fluid sizing may handle values for all breakpoints but for
    // big size screens we use font aspect ratio same like on
    // smaller screens (smaller than breakpoint XL or L).
    // Into "up" object we put last item of values array
    // (if it has not values for all breakpoints) and then,
    // they are used as "theme.breakpoints.up".
    const up: {
        [key: string]: {
            [key: string]: {
                value: number;
                important?: boolean;
                units?: EUnits;
                formatter?: (val: number, breakpoint: string) => string;
            };
        };
    } = {};

    result += Object.keys(breakpoints)
        .map((key, index) => {
            let keyMediaQuery = ``;
            properties.forEach(({ prop, values, important, units, formatter }) => {
                if (
                    values[index] &&
                    values.length - 1 === index &&
                    Object.keys(breakpoints).length - 1 !== index
                ) {
                    up[key] = up[key] || {};
                    up[key][prop] = {
                        value: values[index] as number,
                        important,
                        units: units ?? unit,
                        formatter: formatter
                    };
                    return;
                }
                if (!values[index]) return;
                const value =
                    units === EUnits.PC
                        ? calcPc(values[index] as number)
                        : calcVw(values[index] as number, key);

                keyMediaQuery += formatter
                    ? `${prop}:${formatter(value, key)} ${important ? '!important' : ''};`
                    : `${prop}:${value}${units ?? unit} ${important ? '!important' : ''};`;
            });

            return keyMediaQuery
                ? `${theme.breakpoints.only(key as TBreakpoint)} {${keyMediaQuery}}`
                : '';
        })
        .join('');

    result += Object.keys(breakpoints)
        .map((key) => {
            if (!up[key]) return ``;
            let keyMediaQuery = `${theme.breakpoints.up(key as TBreakpoint)} {`;
            Object.keys(up[key]).forEach((prop) => {
                const units = up[key][prop].units ?? unit;
                const value =
                    units === EUnits.PC
                        ? calcPc(up[key][prop].value)
                        : calcVw(up[key][prop].value as number, key);

                keyMediaQuery += up[key][prop].formatter
                    ? `${prop}:${up[key][prop].formatter(value)} ${
                          up[key][prop].important ? '!important' : ''
                      };`
                    : `${prop}:${value}${units} ${up[key][prop].important ? '!important' : ''};`;
            });
            return `${keyMediaQuery}}`;
        })
        .join('');

    return result;
};
