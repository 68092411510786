import React from 'react';
import * as Styled from './OxHygenePoint.styled';

type TProps = {
    order?: number;
    title?: string;
    description?: string;
};

export const OxHygenePoint = ({ description, order, title }: TProps): JSX.Element => {
    const formattedTitle = `${order ? `${order}. ` : ''}${title ?? ''}`;
    return (
        <Styled.Container>
            {formattedTitle && <Styled.Title>{formattedTitle}</Styled.Title>}
            {description && <Styled.Description>{description}</Styled.Description>}
        </Styled.Container>
    );
};
