import { TBreakpoint, TBreakpoints } from 'src/types/styles.types';

/**
 *  Original Code:
 * https://github.com/mui-org/material-ui/blob/89687f38cae750650555772ba4d821c9084d8dfc/packages/material-ui/src/styles/createBreakpoints.js
 *
 */
import { EBreakpoints } from 'src/config/enums';

// Sorted ASC by size. That's important.
// It can't be configured as it's used statically for propTypes.
export const keys: TBreakpoint[] = ['xs', 's', 'm', 'l', 'xl'];

// Keep in mind that @media is inclusive by the CSS specification.
export default function createBreakpoints(): TBreakpoints {
    // The breakpoint **start** at this value.
    // For instance with the first breakpoint xs: [xs, sm[.
    const values = {
        xs: EBreakpoints.ExtraSmall,
        s: EBreakpoints.Small,
        m: EBreakpoints.Medium,
        l: EBreakpoints.Large,
        xl: EBreakpoints.ExtraLarge
    };
    const unit = 'px';
    const step = 5;

    function up(key: number | TBreakpoint): string {
        const value =
            typeof values[key as TBreakpoint] === 'number' ? values[key as TBreakpoint] : key;
        return `@media (min-width:${value}${unit})`;
    }

    function down(key: number | TBreakpoint): string {
        const endIndex = keys.indexOf(key as TBreakpoint) + 1;
        const upperbound = values[keys[endIndex]];

        if (endIndex === keys.length) {
            // xl down applies to all sizes
            return up('xs');
        }

        const value = typeof upperbound === 'number' && endIndex > 0 ? upperbound : key;
        return `@media (max-width:${(value as number) - step / 100}${unit})`;
    }

    function between(start: TBreakpoint, end: TBreakpoint): string {
        const endIndex = keys.indexOf(end) + 1;

        if (endIndex === keys.length) {
            return up(start);
        }

        return (
            `@media (min-width:${values[start]}${unit}) and ` +
            `(max-width:${values[keys[endIndex]] - step / 100}${unit})`
        );
    }

    function only(key: TBreakpoint): string {
        return between(key, key);
    }

    function width(key: TBreakpoint): number {
        return values[key];
    }

    return {
        between,
        down,
        keys,
        only,
        up,
        values,
        width
    };
}
