export const getCookie = function (cname: string): string {
    const name = cname + '=';
    const ca = document.cookie.split(';');
    for (const cookie of ca) {
        let c = cookie;
        while (c.startsWith(' ')) {
            c = c.substring(1);
        }
        if (c.startsWith(name)) {
            return c.substring(name.length, c.length);
        }
    }
    return '';
};
