import { TUser } from 'src/services/api/api.types';
import { userGetUser } from 'src/services/api';
import { ERoles, AdminRoles } from 'src/config/enums';

export const userRoleIsAdmin = (roles: ERoles[]): boolean =>
    roles.some((role) => AdminRoles.includes(role));

export const getLoggedUserRoles = async (): Promise<ERoles[] | null> => {
    const user = await userGetUser();
    if (!user || !user.roles) return null;
    return user.roles;
};

export const isUserCustomer = async (user?: TUser): Promise<boolean | null> => {
    const userRoles = user?.roles || (await getLoggedUserRoles());
    if (!userRoles) return null;
    return !userRoleIsAdmin(userRoles);
};

export const isUserAdmin = async (user?: TUser): Promise<boolean | null> => {
    const userRoles = user?.roles || (await getLoggedUserRoles());
    if (!userRoles) return null;
    return userRoleIsAdmin(userRoles);
};

export const checkIsUserRole = async (role: ERoles, user?: TUser): Promise<boolean | null> => {
    const userRoles = user?.roles || (await getLoggedUserRoles());
    if (!userRoles) return null;
    return userRoles.includes(role);
};

export const isUserReceptionist = async (user?: TUser): Promise<boolean | null> => {
    return checkIsUserRole(ERoles.Receptionist, user);
};

export const isUserPractitioner = async (user?: TUser): Promise<boolean | null> => {
    return checkIsUserRole(ERoles.Practitioner, user);
};

export const isUserBarista = async (user?: TUser): Promise<boolean | null> => {
    return checkIsUserRole(ERoles.Barista, user);
};

export const isUserNurse = async (user?: TUser): Promise<boolean | null> => {
    return checkIsUserRole(ERoles.Nurse, user);
};

export const isUserMakeUpArtist = async (user?: TUser): Promise<boolean | null> => {
    return checkIsUserRole(ERoles.MakeupArtist, user);
};

export const isUserLoggedIn = async (): Promise<boolean> => {
    const userResponse = await userGetUser();
    return !!userResponse?.id && !!userResponse?.email;
};
