import { EApiEndpoint } from 'src/config/enums';
import { apiPostCall } from './api.core';
import { applyValues } from 'src/utils/applyValues';
import { pushToDatalayer } from 'src/services/datalayer/pushToDatalayer';

export const subscribeToNewsletter = async (email: string): Promise<unknown> => {
    return await apiPostCall(applyValues(EApiEndpoint.NewsletterSubscribe, { email })).then(
        (response) => {
            if (response.status === 'error' || response.errors) {
                pushToDatalayer('newsletter-subscribe-error');
            } else {
                pushToDatalayer('newsletter-subscribe-success');
            }

            return response;
        }
    );
};
