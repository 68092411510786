import * as Styled from './OxMenuNewsletter.styled';

import { EInputTheme, OxThemedInput } from 'src/components/OxThemedInput';
import { EOxInputType, OxInput } from 'src/components/OxInput';
import React, { useState } from 'react';

import { EAlertVariant } from 'src/context/AlertContext';
import { OnyxError } from 'src/helpers/OnyxError';
import { TMenuAnimationOptions } from 'src/components/OxMenu';
import { subscribeToNewsletter } from 'src/services/api/newsletter';
import { fireEvent } from 'src/helpers/TagManager';

type TProps = TMenuAnimationOptions;

export const OxMenuNewsletter = (props: SCProps<'div', TProps>): JSX.Element => {
    const [subscribed, setSubscribed] = useState(false);
    const [inputPlaceholder, setInputPlaceholder] = useState('Sign up now');

    const handleFormSubmit = async (data: FormData): Promise<void> => {
        const email = data.get('email') as string;

        if (!email) return;

        const result = await subscribeToNewsletter(email);
        if (!result.errors) {
            fireEvent({ event: 'subscriptionFormSubmit' });
            setSubscribed(true);
            setTimeout(() => {
                setSubscribed(false);
            }, [3000]);
        } else {
            if (Array.isArray(result.errors) && result.errors[0]) {
                if (result.errors[0].errorType === 'INVALID_EMAIL') {
                    throw new OnyxError({
                        type: EAlertVariant.Error,
                        header: 'ERROR ALERT: OMN1',
                        title: 'Invalid email address',
                        message:
                            'The email address you entered appears to be invalid. Please check your details and try again, if the problem persists please contact us.'
                    });
                } else {
                    throw new OnyxError({
                        type: EAlertVariant.Error,
                        header: 'ERROR ALERT: OMN2',
                        title: 'Something went wrong',
                        message:
                            'Please check your details and try again, if the problem persists please contact us.'
                    });
                }
            }
        }
    };

    return (
        <Styled.Container {...props}>
            <Styled.Wrapper show={props.show} animationTime={props.animationTime}>
                <span>NEWSLETTER</span>
                <h4>Join the Club</h4>
                <Styled.Content>
                    <Styled.InputContainer
                        id="newsletter-menu-form"
                        onFormSubmit={handleFormSubmit}
                        hide={subscribed}
                    >
                        {({ submitButtonProps }: any): JSX.Element => (
                            <OxThemedInput theme={EInputTheme.Gold}>
                                <OxInput
                                    name="email"
                                    type={EOxInputType.Email}
                                    placeholder={inputPlaceholder}
                                    onFocus={(): void => setInputPlaceholder('Type your email')}
                                    onBlur={(): void => setInputPlaceholder('Sign up now')}
                                    required
                                />
                                <Styled.IconButton type="submit" {...submitButtonProps}>
                                    <Styled.SubmitIcon
                                        name="arrow-1"
                                        title={'Submit'}
                                        isWhite={inputPlaceholder === 'Type your email'}
                                    />
                                </Styled.IconButton>
                            </OxThemedInput>
                        )}
                    </Styled.InputContainer>
                    <Styled.SubscribedMessage show={subscribed}>
                        Thank you for subscribing.
                    </Styled.SubscribedMessage>
                </Styled.Content>
            </Styled.Wrapper>
        </Styled.Container>
    );
};
