import { apiGetCall, apiPatchCall, apiPostCall } from './api.core';

import { EApiEndpoint } from 'src/config/enums';
import { TUser } from './api.types';

type TUserLoginResult = {
    login: boolean;
    token?: string;
    '2fa_complete': boolean;
};

type TUserPasswordlessLoginResult = {
    login_link: boolean;
};

type TUserLoginCodeResult = {
    login: boolean;
    token?: string;
    error?: string;
};

type TUserPasswordlessLoginCheckResult = {
    login?: boolean;
    token?: string;
    error?: string;
};

type TTwoFactorResult = {
    token: string;
    '2fa_complete': boolean;
    error?: string;
};

export const userGetUser = async (): Promise<TUser | null> => {
    return await apiGetCall(EApiEndpoint.User, {
        credentials: true
    });
};

export const userPostLogin = async (values: {
    username: string;
    password: string;
}): Promise<TUserLoginResult | null> => {
    return await apiPostCall(EApiEndpoint.StaffLogin, JSON.stringify(values), {
        withCredentials: true
    });
};

export const userPostLoginPasswordless = async (values: {
    username: string;
}): Promise<TUserPasswordlessLoginResult | null> => {
    return await apiPostCall(EApiEndpoint.UserLogin, JSON.stringify(values), {
        withCredentials: true
    });
};

export const userPostLoginCode = async (values: {
    user: string;
    passcode: string;
}): Promise<TUserLoginCodeResult | null> => {
    return await apiPostCall(EApiEndpoint.UserLoginCode, JSON.stringify(values));
};

export const userPostLoginPasswordlessCheck = async (values: {
    user: string;
    expires: string;
    hash: string;
}): Promise<TUserPasswordlessLoginCheckResult | null> => {
    const params = values ? new URLSearchParams(values).toString() : '';
    return await apiPostCall(EApiEndpoint.UserLoginCheck, params, {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        withCredentials: true
    });
};

export const userPasswordResetRequest = async (values: { email: string }): Promise<[] | null> => {
    return await apiPostCall(EApiEndpoint.UserPasswordResetRequest, JSON.stringify(values));
};

export const userPasswordReset = async (values: {
    token: string;
    password: string;
}): Promise<[] | null> => {
    return await apiPostCall(EApiEndpoint.UserPasswordReset, JSON.stringify(values));
};

export const twoFactorAuthCheck = async (values?: FormData): Promise<TTwoFactorResult | null> => {
    const params = values ? new URLSearchParams(values).toString() : '';
    return await apiPostCall(EApiEndpoint.TwoFactorAuthCheck, params, {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        withCredentials: true
    });
};

export const userCreateAccount = async (values: {
    clinicId?: number;
    email?: string;
    firstname?: string;
    lastname?: string;
    mobile?: string;
    newsletter?: boolean;
    password?: string;
    acceptedPrivacyPolicy?: boolean;
}): Promise<TUser | null> => {
    return await apiPostCall(EApiEndpoint.User, JSON.stringify(values));
};

export const userPassword = async (values: { new?: string; current?: string }): Promise<null> => {
    return await apiPatchCall(EApiEndpoint.UserPassword, JSON.stringify(values), {
        credentials: true
    });
};
