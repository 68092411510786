import styled, { css } from 'styled-components';

export const SVG = styled.svg<{ size?: number | string }>(({ size }) =>
    size
        ? typeof size === 'string'
            ? css`
                  height: ${size};
              `
            : css`
                  height: ${size}px;
              `
        : null
);
