import styled from 'styled-components';
import { ESizes } from 'src/config/enums';
import { fluidSizing } from 'src/utils';

export const OxContainerGutter = [ESizes.GutterXS, ESizes.GutterS, ESizes.GutterM];

export const OxContainer = styled.div`
    width: 100%;
    ${fluidSizing([
        {
            prop: 'padding-left',
            values: OxContainerGutter
        },
        {
            prop: 'padding-right',
            values: OxContainerGutter
        }
    ])}
`;
