import * as Styled from './OxMenuNavigation.styled';

import React, { useContext } from 'react';

import { ERoutes } from 'src/config/enums';
import { LayoutContext } from 'src/context/LayoutContext';
import { OxLink } from '../OxLink';
import { TMenuAnimationOptions } from 'src/components/OxMenu';
import { graphql, useStaticQuery } from 'gatsby';
import { Page } from 'src/services/cms/cms.types';

type TProps = TMenuAnimationOptions;

type TMenuItem = {
    title: string;
    linkTo: string;
};

export const OxMenuNavigation = ({
    animationTime,
    show,
    ...props
}: SCProps<'div', TProps>): JSX.Element => {
    const { setMenuIsOpen } = useContext(LayoutContext);
    const onLinkClick = (): void => {
        setMenuIsOpen(false);
    };

    const {
        pages: { nodes: pages }
    } = useStaticQuery(
        graphql`
            query {
                pages: allSanityPage(
                    sort: { fields: [order], order: ASC }
                    filter: {
                        slug: { current: { ne: null } }
                        enabled: { eq: true }
                        show_in_nav: { eq: true }
                    }
                ) {
                    nodes {
                        name
                        slug {
                            current
                        }
                    }
                }
            }
        `
    );

    const menu: TMenuItem[] = [
        ...(pages?.map(({ name, slug }: Page) => ({
            title: name,
            linkTo: slug?.current
        })) ?? []),
        { title: 'My Account', linkTo: ERoutes.PanelCustomerLogin },
        { title: 'Book Now', linkTo: ERoutes.Appointment }
    ];

    return (
        <Styled.Navigation animationTime={animationTime} show={show} {...props}>
            <nav>
                <ul>
                    {menu.map((item, index) => (
                        <Styled.NavItem
                            animationTime={animationTime}
                            show={show}
                            total={menu.length}
                            key={index}
                            index={index}
                        >
                            <OxLink onClick={onLinkClick} to={item.linkTo} data-title={item.title}>
                                {item.title}
                            </OxLink>
                        </Styled.NavItem>
                    ))}
                </ul>
            </nav>
        </Styled.Navigation>
    );
};
