import { EFontWeight, EFonts } from 'src/config/enums';
import { moveLeftBack, showWithDelayAnimation, waterfallAnimation } from 'src/styles/animations';
import styled, { css } from 'styled-components';

import { OxContainer } from 'src/components/OxContainer';
import { OxForm } from '../OxForm';
import { TMenuAnimationOptions } from 'src/components/OxMenu';
import { createTextStyle } from 'src/utils/createTextStyle';
import { fluidSizing } from 'src/utils';
import { OxIcon } from 'src/components/OxIcon';

export const Container = styled(OxContainer)<TMenuAnimationOptions>(
    ({ theme, show, animationTime }) => css`
        grid-area: MenuNewsletter;
        display: flex;
        align-items: flex-start;
        span {
            ${createTextStyle(EFonts.Montserrat, EFontWeight.SemiBold, [12, 12, 12], [16, 16, 16])}
            ${fluidSizing([{ prop: 'margin-bottom', values: [10, 10, 10] }])}
        }
        h4 {
            font-weight: ${theme.fontWeights.medium};
            ${fluidSizing([
                { prop: 'font-size', values: [20, 20, 21] },
                { prop: 'line-height', values: [28, 28, 24] },
                { prop: 'margin-bottom', values: [5, 5, 8] }
            ])}
        }
        ${showWithDelayAnimation(show, animationTime * 0.5, animationTime * 0.5)}
        ${theme.breakpoints.up('s')} {
            align-items: center;
            padding-right: 0;
            ${fluidSizing([{ prop: 'margin-bottom', values: [15, 15, 15] }])}
        }
    `
);

export const Wrapper = styled.div<TMenuAnimationOptions>(
    ({ theme, show, animationTime }) => css`
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        ${waterfallAnimation(show, 3, animationTime)}
        ${theme.breakpoints.only('xs')} {
            ${fluidSizing([
                { prop: 'padding-bottom', values: [15, 15, 15] },
                { prop: 'margin-bottom', values: [8, 8, 8] }
            ])};
            border-bottom: 1px solid ${theme.colors.primary.main};
        }
    `
);

export const InputContainer = styled(OxForm)<{ hide: boolean }>(
    ({ theme, hide }) => css`
        position: relative;
        transition: 400ms;
        visibility: visible;
        opacity: 1;
        ${hide &&
        css`
            visibility: hidden;
            opacity: 0;
        `}
        input {
            ${fluidSizing([{ prop: 'padding-right', values: [30, 30, 30] }])}
            ${createTextStyle(EFonts.Montserrat, EFontWeight.Medium, [8, 8, 8], [14, 14, 14])}
        }
    `
);

export const IconButton = styled.button`
    width: auto;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    ${fluidSizing([
        { prop: 'right', values: [10, 10, 10] },
        { prop: 'padding', values: [5, 5, 5] }
    ])}
    svg {
        ${fluidSizing([{ prop: 'height', values: [10, 10, 10] }])}
    }
    &:hover {
        svg {
            animation: ${moveLeftBack} 1.5s;
        }
    }
`;

export const SubscribedMessage = styled.p<{ show: boolean }>(
    ({ theme, show }) => css`
        visibility: hidden;
        opacity: 0;
        transition: 400ms;
        position: absolute;
        bottom: 35%;
        ${createTextStyle(EFonts.Montserrat, EFontWeight.Bold, [10, 10, 10], [14, 14, 14])}
        ${show &&
        css`
            opacity: 1;
            visibility: visible;
        `}
    ${fluidSizing([
            { prop: 'padding-top', values: [10, 10, 10] },
            { prop: 'width', values: [200, 200, 200] }
        ])}
    `
);

export const Content = styled.div`
    position: relative;
`;

export const SubmitIcon = styled(OxIcon)<{ isWhite: boolean }>(
    ({ theme, isWhite }) => css`
        ${isWhite &&
        css`
            color: ${theme.colors.basic.white};
        `}
    `
);
