exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-appointment-tsx": () => import("./../../../src/pages/appointment.tsx" /* webpackChunkName: "component---src-pages-appointment-tsx" */),
  "component---src-pages-feedback-tsx": () => import("./../../../src/pages/feedback.tsx" /* webpackChunkName: "component---src-pages-feedback-tsx" */),
  "component---src-pages-marketing-page-1-tsx": () => import("./../../../src/pages/marketing/page1.tsx" /* webpackChunkName: "component---src-pages-marketing-page-1-tsx" */),
  "component---src-pages-marketing-page-2-tsx": () => import("./../../../src/pages/marketing/page2.tsx" /* webpackChunkName: "component---src-pages-marketing-page-2-tsx" */),
  "component---src-pages-marketing-page-3-tsx": () => import("./../../../src/pages/marketing/page3.tsx" /* webpackChunkName: "component---src-pages-marketing-page-3-tsx" */),
  "component---src-pages-marketing-page-4-tsx": () => import("./../../../src/pages/marketing/page4.tsx" /* webpackChunkName: "component---src-pages-marketing-page-4-tsx" */),
  "component---src-pages-marketing-page-5-tsx": () => import("./../../../src/pages/marketing/page5.tsx" /* webpackChunkName: "component---src-pages-marketing-page-5-tsx" */),
  "component---src-pages-panel-tsx": () => import("./../../../src/pages/panel.tsx" /* webpackChunkName: "component---src-pages-panel-tsx" */),
  "component---src-pages-survey-tsx": () => import("./../../../src/pages/survey.tsx" /* webpackChunkName: "component---src-pages-survey-tsx" */),
  "component---src-templates-cms-cms-tsx": () => import("./../../../src/templates/cms/cms.tsx" /* webpackChunkName: "component---src-templates-cms-cms-tsx" */),
  "component---src-templates-personality-archetype-personality-archetype-tsx": () => import("./../../../src/templates/personality-archetype/personality-archetype.tsx" /* webpackChunkName: "component---src-templates-personality-archetype-personality-archetype-tsx" */)
}

