import {
    TBookingFlowContextClient,
    TBookingFlowContextState
} from 'src/context/BookingFlowContext';

export enum EBookingFlowAction {
    SetProcessing = 'SET_PROCESSING',
    SetGeoipCountry = 'SET_GEOIP_COUNTRY',
    SetBookingFlowState = 'SET_BOOKING_FLOW_STATE',
    SetConsultationDate = 'SET_CONSULTATION_DATE',
    SetConsultationTime = 'SET_CONSULTATION_TIME',
    SetCurrentStage = 'SET_CURRENT_STAGE',
    SetConsultationLocationId = 'SET_CONSULTATION_LOCATION_ID',
    SetPractitioner = 'SET_PRACTITIONER',
    SetPreferredPractitioner = 'SET_PREFERRED_PRACTITIONER',
    SetPractitionerFirstVisit = 'SET_PRACTITIONER_FIRST_VISIT',
    SetAppointmentTypeId = 'SET_APPOINTMENT_TYPE_ID',
    SetChooseDoctorForMe = 'SET_CHOOSE_DOCTOR_FOR_ME',
    SetAppointments = 'SET_APPOINTMENTS',
    SetAppointmentTypes = 'SET_APPOINTMENT_TYPES',
    SetClinic = 'SET_CLINIC',
    SetClient = 'SET_CLIENT',
    SetUser = 'SET_USER',
    SetFirstName = 'SET_FIRST_NAME',
    SetSecondName = 'SET_SECOND_NAME',
    SetEmail = 'SET_EMAIL',
    SetMobile = 'SET_MOBILE',
    SetNewlyCreatedUser = 'SET_NEWLY_CREATED_USER',
    SetCart = 'SET_CART',
    SetIsNonZeroValueCart = 'SET_IS_NON_ZERO_VALUE_CART',
    SetUserLoggedIn = 'SET_USER_LOGGED_IN',
    SetFullAppointmentForEdit = 'SET_FULL_APPOINTMENT',
    SetPaymentObject = 'SET_PAYMENT_OBJECT',
    SetStripe = 'SET_STRIPE',
    SetStripeElements = 'SET_STRIPE_ELEMENTS',
    SetStripeCustomerIntent = 'SET_STRIPE_CUSTOMER_INTENT',
    SetHasPaymentMethods = 'SET_HAS_PAYMENT_METHODS',
    SetPaymentDisabled = 'SET_PAYMENT_DISABLED'
}

export type TBookingFlowReducerAction = {
    type: EBookingFlowAction;
    payload?: any;
};

export const initialBookingFlowState: TBookingFlowContextState = {
    shouldValidateAppointment: false,
    consultationDate: undefined,
    consultationTime: undefined,
    currentStage: 0,
    consultationLocationId: undefined,
    practitioner: undefined,
    practitionerFirstVisit: undefined,
    appointmentTypeId: undefined,
    chooseDoctorForMe: undefined,
    appointments: [],
    appointmentTypes: [],
    newlyCreatedUser: undefined,
    userLoggedIn: undefined,
    client: undefined,
    cart: undefined,
    isNonZeroValueCart: false,
    fullAppointment: undefined,
    paymentDisabled: true,
    paymentObject: {
        stripe: null,
        elements: null,
        customerIntent: ''
    }
};

export const bookingFlowReducer = (
    state: TBookingFlowContextState,
    action: TBookingFlowReducerAction
): TBookingFlowContextState => {
    switch (action.type) {
        case EBookingFlowAction.SetBookingFlowState:
            return { ...initialBookingFlowState, ...action.payload };
        case EBookingFlowAction.SetProcessing:
            return { ...state, processing: action.payload };
        case EBookingFlowAction.SetGeoipCountry:
            return { ...state, geoipCountry: action.payload };
        case EBookingFlowAction.SetConsultationDate:
            return { ...state, consultationDate: action.payload };
        case EBookingFlowAction.SetConsultationTime:
            return { ...state, consultationTime: action.payload };
        case EBookingFlowAction.SetCurrentStage:
            return { ...state, currentStage: action.payload };
        case EBookingFlowAction.SetConsultationLocationId:
            return { ...state, consultationLocationId: action.payload };
        case EBookingFlowAction.SetPreferredPractitioner:
            return { ...state, preferredPractitioner: action.payload };
        case EBookingFlowAction.SetPractitioner:
            return { ...state, practitioner: action.payload };
        case EBookingFlowAction.SetPractitionerFirstVisit:
            return { ...state, practitionerFirstVisit: action.payload };
        case EBookingFlowAction.SetAppointmentTypeId:
            return { ...state, appointmentTypeId: action.payload };
        case EBookingFlowAction.SetChooseDoctorForMe:
            return { ...state, chooseDoctorForMe: action.payload };
        case EBookingFlowAction.SetAppointments:
            return { ...state, appointments: action.payload };
        case EBookingFlowAction.SetAppointmentTypes:
            return { ...state, appointmentTypes: action.payload };
        case EBookingFlowAction.SetClient:
            return { ...state, client: action.payload };
        case EBookingFlowAction.SetClinic:
            return { ...state, clinic: action.payload };
        case EBookingFlowAction.SetUser:
            return { ...state, user: action.payload };
        case EBookingFlowAction.SetFirstName:
            state.client = state.client ?? ({} as TBookingFlowContextClient);
            state.client.firstName = action.payload;
            return { ...state };
        case EBookingFlowAction.SetSecondName:
            state.client = state.client ?? ({} as TBookingFlowContextClient);
            state.client.secondName = action.payload;
            return { ...state };
        case EBookingFlowAction.SetEmail:
            state.client = state.client ?? ({} as TBookingFlowContextClient);
            state.client.email = action.payload;
            return { ...state };
        case EBookingFlowAction.SetMobile:
            state.client = state.client ?? ({} as TBookingFlowContextClient);
            state.client.mobile = action.payload;
            return { ...state };
        case EBookingFlowAction.SetNewlyCreatedUser:
            return { ...state, newlyCreatedUser: action.payload };
        case EBookingFlowAction.SetCart:
            return { ...state, cart: action.payload };
        case EBookingFlowAction.SetIsNonZeroValueCart:
            return { ...state, isNonZeroValueCart: action.payload };
        case EBookingFlowAction.SetUserLoggedIn:
            return { ...state, userLoggedIn: action.payload };
        case EBookingFlowAction.SetFullAppointmentForEdit:
            return { ...state, fullAppointment: action.payload };
        case EBookingFlowAction.SetPaymentObject:
            return { ...state, paymentObject: action.payload };
        case EBookingFlowAction.SetStripe:
            state.paymentObject = state.paymentObject ?? {};
            state.paymentObject.stripe = action.payload;
            return { ...state };
        case EBookingFlowAction.SetStripeElements:
            state.paymentObject = state.paymentObject ?? {};
            state.paymentObject.elements = action.payload;
            return { ...state };
        case EBookingFlowAction.SetStripeCustomerIntent:
            state.paymentObject = state.paymentObject ?? {};
            state.paymentObject.customerIntent = action.payload;
            return { ...state };
        case EBookingFlowAction.SetHasPaymentMethods:
            return { ...state, hasPaymentMethods: action.payload };
        case EBookingFlowAction.SetPaymentDisabled:
            return { ...state, paymentDisabled: action.payload };

        default:
            return state;
    }
};
