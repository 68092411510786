import { TSupport } from 'src/types/styles.types';

const createSupport = (): TSupport => ({
    isIOS: '@supports (-webkit-overflow-scrolling: touch)',
    isMSEdge: '@supports (-ms-ime-align: auto)',
    pointerDevice: '@media (hover: hover) and (pointer: fine)',
    touchDevice: '@media (hover: none) and (pointer: coarse)',
    isHorizontal: '@media (orientation: landscape)'
});

export default createSupport;
