import { withPrefix } from 'gatsby';

const fonts = `
@font-face {
  font-family: Hatton;
  src: url(${withPrefix('/fonts/Hatton/Hatton-Semibold.eot')});
  src: url(${withPrefix('/fonts/Hatton/Hatton-Semibold.eot?#iefix')}) format('embedded-opentype'),
      url(${withPrefix('/fonts/Hatton/Hatton-Semibold.woff2')}) format('woff2'),
      url(${withPrefix('/fonts/Hatton/Hatton-Semibold.woff')}) format('woff'),
      url(${withPrefix('/fonts/Hatton/Hatton-Semibold.ttf')}) format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Hatton;
  src: url(${withPrefix('/fonts/Hatton/Hatton-Medium.eot')});
  src: url(${withPrefix('/fonts/Hatton/Hatton-Medium.eot?#iefix')}) format('embedded-opentype'),
      url(${withPrefix('/fonts/Hatton/Hatton-Medium.woff2')}) format('woff2'),
      url(${withPrefix('/fonts/Hatton/Hatton-Medium.woff')}) format('woff'),
      url(${withPrefix('/fonts/Hatton/Hatton-Medium.ttf')}) format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Hatton;
  src: url(${withPrefix('/fonts/Hatton/Hatton-Ultralight.eot')});
  src: url(${withPrefix('/fonts/Hatton/Hatton-Ultralight.eot?#iefix')}) format('embedded-opentype'),
      url(${withPrefix('/fonts/Hatton/Hatton-Ultralight.woff2')}) format('woff2'),
      url(${withPrefix('/fonts/Hatton/Hatton-Ultralight.woff')}) format('woff'),
      url(${withPrefix('/fonts/Hatton/Hatton-Ultralight.ttf')}) format('truetype');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Hatton;
  src: url(${withPrefix('/fonts/Hatton/Hatton-Light.eot')});
  src: url(${withPrefix('/fonts/Hatton/Hatton-Light.eot?#iefix')}) format('embedded-opentype'),
      url(${withPrefix('/fonts/Hatton/Hatton-Light.woff2')}) format('woff2'),
      url(${withPrefix('/fonts/Hatton/Hatton-Light.woff')}) format('woff'),
      url(${withPrefix('/fonts/Hatton/Hatton-Light.ttf')}) format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Hatton;
  src: url(${withPrefix('/fonts/Hatton/Hatton-Bold.eot')});
  src: url(${withPrefix('/fonts/Hatton/Hatton-Bold.eot?#iefix')}) format('embedded-opentype'),
      url(${withPrefix('/fonts/Hatton/Hatton-Bold.woff2')}) format('woff2'),
      url(${withPrefix('/fonts/Hatton/Hatton-Bold.woff')}) format('woff'),
      url(${withPrefix('/fonts/Hatton/Hatton-Bold.ttf')}) format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url(${withPrefix('/fonts/Montserrat/montserrat-v15-latin-300.eot')}); /* IE9 Compat Modes */
  src: local(''),
    url(${withPrefix(
        '/fonts/Montserrat/montserrat-v15-latin-300.eot?#iefix'
    )}) format('embedded-opentype'), /* IE6-IE8 */
    url(${withPrefix(
        '/fonts/Montserrat/montserrat-v15-latin-300.woff2'
    )}) format('woff2'), /* Super Modern Browsers */
    url(${withPrefix(
        '/fonts/Montserrat/montserrat-v15-latin-300.woff'
    )}) format('woff'), /* Modern Browsers */
    url(${withPrefix(
        '/fonts/Montserrat/montserrat-v15-latin-300.ttf'
    )}) format('truetype'), /* Safari, Android, iOS */
    url(${withPrefix(
        '/fonts/Montserrat/montserrat-v15-latin-300.svg#Montserrat'
    )}) format('svg'); /* Legacy iOS */
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url(${withPrefix(
      '/fonts/Montserrat/montserrat-v15-latin-300italic.eot'
  )}); /* IE9 Compat Modes */
    src: local(''),
    url(${withPrefix(
        '/fonts/Montserrat/montserrat-v15-latin-300italic.eot?#iefix'
    )}) format('embedded-opentype'), /* IE6-IE8 */
    url(${withPrefix(
        '/fonts/Montserrat/montserrat-v15-latin-300italic.woff2'
    )}) format('woff2'), /* Super Modern Browsers */
    url(${withPrefix(
        '/fonts/Montserrat/montserrat-v15-latin-300italic.woff'
    )}) format('woff'), /* Modern Browsers */
    url(${withPrefix(
        '/fonts/Montserrat/montserrat-v15-latin-300italic.ttf'
    )}) format('truetype'), /* Safari, Android, iOS */
    url(${withPrefix(
        '/fonts/Montserrat/montserrat-v15-latin-300italic.svg#Montserrat'
    )}) format('svg'); /* Legacy iOS */
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url(${withPrefix('/fonts/Montserrat/montserrat-v15-latin-500.eot')}); /* IE9 Compat Modes */
  src: local(''),
    url(${withPrefix(
        '/fonts/Montserrat/montserrat-v15-latin-500.eot?#iefix'
    )}) format('embedded-opentype'), /* IE6-IE8 */
    url(${withPrefix(
        '/fonts/Montserrat/montserrat-v15-latin-500.woff2'
    )}) format('woff2'), /* Super Modern Browsers */
    url(${withPrefix(
        '/fonts/Montserrat/montserrat-v15-latin-500.woff'
    )}) format('woff'), /* Modern Browsers */
    url(${withPrefix(
        '/fonts/Montserrat/montserrat-v15-latin-500.ttf'
    )}) format('truetype'), /* Safari, Android, iOS */
    url(${withPrefix(
        '/fonts/Montserrat/montserrat-v15-latin-500.svg#Montserrat'
    )}) format('svg'); /* Legacy iOS */
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url(${withPrefix(
      '/fonts/Montserrat/montserrat-v15-latin-500italic.eot'
  )}); /* IE9 Compat Modes */
  src: local(''),
    url(${withPrefix(
        '/fonts/Montserrat/montserrat-v15-latin-500italic.eot?#iefix'
    )}) format('embedded-opentype'), /* IE6-IE8 */
    url(${withPrefix(
        '/fonts/Montserrat/montserrat-v15-latin-500italic.woff2'
    )}) format('woff2'), /* Super Modern Browsers */
    url(${withPrefix(
        '/fonts/Montserrat/montserrat-v15-latin-500italic.woff'
    )}) format('woff'), /* Modern Browsers */
    url(${withPrefix(
        '/fonts/Montserrat/montserrat-v15-latin-500italic.ttf'
    )}) format('truetype'), /* Safari, Android, iOS */
    url(${withPrefix(
        '/fonts/Montserrat/montserrat-v15-latin-500italic.svg#Montserrat'
    )}) format('svg'); /* Legacy iOS */
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url(${withPrefix('/fonts/Montserrat/montserrat-v15-latin-600.eot')}); /* IE9 Compat Modes */
  src: local(''),
    url(${withPrefix(
        '/fonts/Montserrat/montserrat-v15-latin-600.eot?#iefix'
    )}) format('embedded-opentype'), /* IE6-IE8 */
    url(${withPrefix(
        '/fonts/Montserrat/montserrat-v15-latin-600.woff2'
    )}) format('woff2'), /* Super Modern Browsers */
    url(${withPrefix(
        '/fonts/Montserrat/montserrat-v15-latin-600.woff'
    )}) format('woff'), /* Modern Browsers */
    url(${withPrefix(
        '/fonts/Montserrat/montserrat-v15-latin-600.ttf'
    )}) format('truetype'), /* Safari, Android, iOS */
    url(${withPrefix(
        '/fonts/Montserrat/montserrat-v15-latin-600.svg#Montserrat'
    )}) format('svg'); /* Legacy iOS */
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url(${withPrefix(
      '/fonts/Montserrat/montserrat-v15-latin-600italic.eot'
  )}); /* IE9 Compat Modes */
  src: local(''),
    url(${withPrefix(
        '/fonts/Montserrat/montserrat-v15-latin-600italic.eot?#iefix'
    )}) format('embedded-opentype'), /* IE6-IE8 */
    url(${withPrefix(
        '/fonts/Montserrat/montserrat-v15-latin-600italic.woff2'
    )}) format('woff2'), /* Super Modern Browsers */
    url(${withPrefix(
        '/fonts/Montserrat/montserrat-v15-latin-600italic.woff'
    )}) format('woff'), /* Modern Browsers */
    url(${withPrefix(
        '/fonts/Montserrat/montserrat-v15-latin-600italic.ttf'
    )}) format('truetype'), /* Safari, Android, iOS */
    url(${withPrefix(
        '/fonts/Montserrat/montserrat-v15-latin-600italic.svg#Montserrat'
    )}) format('svg'); /* Legacy iOS */
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url(${withPrefix('/fonts/Montserrat/montserrat-v15-latin-700.eot')}); /* IE9 Compat Modes */
  src: local(''),
    url(${withPrefix(
        '/fonts/Montserrat/montserrat-v15-latin-700.eot?#iefix'
    )}) format('embedded-opentype'), /* IE6-IE8 */
    url(${withPrefix(
        '/fonts/Montserrat/montserrat-v15-latin-700.woff2'
    )}) format('woff2'), /* Super Modern Browsers */
    url(${withPrefix(
        '/fonts/Montserrat/montserrat-v15-latin-700.woff'
    )}) format('woff'), /* Modern Browsers */
    url(${withPrefix(
        '/fonts/Montserrat/montserrat-v15-latin-700.ttf'
    )}) format('truetype'), /* Safari, Android, iOS */
    url(${withPrefix(
        '/fonts/Montserrat/montserrat-v15-latin-700.svg#Montserrat'
    )}) format('svg'); /* Legacy iOS */
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url(${withPrefix(
      '/fonts/Montserrat/montserrat-v15-latin-700italic.eot'
  )}); /* IE9 Compat Modes */
  src: local(''),
    url(${withPrefix(
        '/fonts/Montserrat/montserrat-v15-latin-700italic.eot?#iefix'
    )}) format('embedded-opentype'), /* IE6-IE8 */
    url(${withPrefix(
        '/fonts/Montserrat/montserrat-v15-latin-700italic.woff2'
    )}) format('woff2'), /* Super Modern Browsers */
    url(${withPrefix(
        '/fonts/Montserrat/montserrat-v15-latin-700italic.woff'
    )}) format('woff'), /* Modern Browsers */
    url(${withPrefix(
        '/fonts/Montserrat/montserrat-v15-latin-700italic.ttf'
    )}) format('truetype'), /* Safari, Android, iOS */
    url(${withPrefix(
        '/fonts/Montserrat/montserrat-v15-latin-700italic.svg#Montserrat'
    )}) format('svg'); /* Legacy iOS */
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}

`;

export default fonts;
